import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_EMPLOYEE_LIST, LOAD_EMPLOYER_LIST } from "./types";
import { fetchEmployeesService, fetchEmployersService } from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setEmployeeList, setEmployerList } from "./actions";

function* handleLoadEmployeeList({
  payload,
}: {
  payload?: any;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeesService, payload);
    yield put(setEmployeeList(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployerList({
  payload,
}: {
  payload?: any;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployersService, payload);
    yield put(setEmployerList(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* adminSaga() {
  yield takeLatest<any>(LOAD_EMPLOYEE_LIST, handleLoadEmployeeList);
  yield takeLatest<any>(LOAD_EMPLOYER_LIST, handleLoadEmployerList);
}
