
export const LOAD_JOBS = "LOAD_JOBS";
export const LOAD_SAVED_JOBS = "LOAD_SAVED_JOBS";
export const LOAD_APPLIED_JOBS = "LOAD_APPLIED_JOBS";
export const LOAD_JOB = "LOAD_JOB";
export const HANDLE_ADD_JOB = "HANDLE_ADD_JOB";
export const HANDLE_UPDATE_JOB = "HANDLE_UPDATE_JOB";
export const HANDLE_DELETE_JOB = "HANDLE_DELETE_JOB";
export const HANDLE_SAVE_JOB = "HANDLE_SAVE_JOB";
export const HANDLE_APPLY_JOB = "HANDLE_APPLY_JOB";
export const SET_JOBS = "SET_JOBS";
export const SET_SAVED_JOBS = "SET_SAVED_JOBS";
export const SET_APPLIED_JOBS = "SET_APPLIED_JOBS";
export const SET_JOB = "SET_JOB";
