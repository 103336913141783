import { jobProps } from "src/interfaces";
import http from "./http";
import { setCurrentRoute, store } from "src/store";

const controller: any = {
  Admin: "Admin/",
  Employer: "Employer/",
  Employee: "Employee/",
  "": "Data/",
};

export const fetchJobsService = async (payload = {}) => {
  const role = store?.getState()?.authUser?.role || "";
  try {
    const responseData = await http.Patch(controller[role] + "GetJobs", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const fetchSavedJobsService = async () => {
  const role = store?.getState()?.authUser?.role || "";
  try {
    const responseData = await http.Get(controller[role] + "GetSavedJobs");
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const saveJobService = async (payload: { id: number }) => {
  const role = store?.getState()?.authUser?.role || "";
  if (!role) {
    store?.dispatch(setCurrentRoute({ path: "/account/login" }));
    return null;
  }
  try {
    const responseData = await http.Post(controller[role] + "SaveJob", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const fetchAppliedJobsService = async () => {
  const role = store?.getState()?.authUser?.role || "";
  try {
    const responseData = await http.Get(controller[role] + "GetAppliedJobs");
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const applyJobService = async (payload: { id: number }) => {
  const role = store?.getState()?.authUser?.role || "";
  if (!role) {
    store?.dispatch(setCurrentRoute({ path: "/account/login" }));
    return null;
  }
  try {
    const responseData = await http.Post(controller[role] + "ApplyJob", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const fetchJobService = async (id: number) => {
  const role = store?.getState()?.authUser?.role || "";
  try {
    const responseData = await http.Get(controller[role] + "GetJobById?id=" + id);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const addJobService = async (payload: jobProps) => {
  const role = store?.getState()?.authUser?.role;
  try {
    const responseData = await http.Post(controller[role] + "AddJob", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const updateJobService = async (payload: jobProps) => {
  const role = store?.getState()?.authUser?.role;
  try {
    const responseData = await http.Post(controller[role] + "UpdateJob", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};


export const deleteJobService = async (payload: { id: number }) => {
  const role = store?.getState()?.authUser?.role;
  try {
    const responseData = await http.Delete(controller[role] + "DeleteJob", payload as any);
    return responseData
  } catch (error:any) {
    throw error;
  }
};
