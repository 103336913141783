import { NavLink } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
  useTheme,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import { connect } from "react-redux";
import { useRef, useState } from "react";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const HeaderUserbox = () => {
  const theme = useTheme();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <List
        disablePadding
        sx={{ display: { md: "flex", xs: "none" } }}
        component={Box}
      >
        {/* <Button
          sx={{ mr: 1 }}
          variant="outlined"
          component={NavLink}
          to="/account/login"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Login"
          />
        </Button> */}
        {/* <Button variant="contained" component={NavLink} to="/account/register">
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="New Registration"
          />
        </Button> */}
      </List>
      <Box sx={{ display: { md: "none", xs: "block" } }}>
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Avatar
            sx={{ backgroundColor: theme.palette.primary.main }}
            variant="circular"
          />
        </UserBoxButton>
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List sx={{ p: 1, boxShadow: 3 }} component="nav">
            <ListItem
              to="/account/login"
              onClick={handleClose}
              component={NavLink}
              sx={{ color: theme.palette.secondary.main }}
            >
              <LoginIcon fontSize="small" />
              <ListItemText primary="Login" sx={{ ml: 1 }} />
            </ListItem>
            <ListItem
              to="/account/register"
              onClick={handleClose}
              component={NavLink}
              sx={{ color: theme.palette.secondary.main }}
            >
              <PersonAddIcon fontSize="small" />
              <ListItemText primary="Register" sx={{ ml: 1 }} />
            </ListItem>
          </List>
        </Popover>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
