import { userValidation, loadCountries, store } from "src/store";
import { loadAllOptions } from "src/store/options";

export const appInitializer = () => {
  var authUser = store.getState().authUser;
  store.dispatch(loadCountries());
  store.dispatch(loadAllOptions());
  if (authUser?.isLoggedIn) {
    store.dispatch(
      userValidation({
        username: authUser?.username,
        role: authUser?.role,
      })
    );
  }
};
