import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SET_EMPLOYEE_DETAIL,
  SET_EMPLOYEE_QUALIFICATIONS,
  SET_EMPLOYEE_SKILLS,
  SET_EMPLOYEE_PROJECTS,
  SET_EMPLOYEE_PREFERRED_LOCATIONS,
  SET_EMPLOYEE_ALL_DETAILS,
  SET_EMPLOYEE_EXPERIENCES,
  SET_EMPLOYEE_RESUMES,
  SET_EMPLOYEE_PROFILE_AVATAR,
  SET_EMPLOYEE_LANGUAGES,
  SET_EMPLOYEE_CERTIFICATES,
  SET_EMPLOYEE_SOCIALMEDIA,
} from "./types";
import { employeeReducer } from "src/interfaces";

const initialeState: employeeReducer = {
  detail: {},
  resumes: [],
  skills: [],
  experiences: [],
  certificates: [],
  projects: [],
  qualifications: [],
  preferredLocations: [],
  languages: [],
  socialMedia: []
};
export const employee = (
  state: employeeReducer = initialeState,
  action: PayloadAction<any> = null
): employeeReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_EMPLOYEE_ALL_DETAILS: {
      return { ...state, ...payload };
    }
    case SET_EMPLOYEE_DETAIL: {
      return { ...state, detail: payload };
    }
    case SET_EMPLOYEE_PROFILE_AVATAR: {
      return { ...state, detail: { ...state.detail, avatar: payload } };
    }
    case SET_EMPLOYEE_RESUMES: {
      return { ...state, resumes: payload };
    }
    case SET_EMPLOYEE_QUALIFICATIONS: {
      return { ...state, qualifications: payload };
    }
    case SET_EMPLOYEE_SKILLS: {
      return { ...state, skills: payload };
    }
    case SET_EMPLOYEE_EXPERIENCES: {
      return { ...state, experiences: payload };
    }
    case SET_EMPLOYEE_CERTIFICATES: {
      return { ...state, certificates: payload };
    }
    case SET_EMPLOYEE_PROJECTS: {
      return { ...state, projects: payload };
    }
    case SET_EMPLOYEE_PREFERRED_LOCATIONS: {
      return { ...state, preferredLocations: payload };
    }
    case SET_EMPLOYEE_LANGUAGES: {
      return { ...state, languages: payload };
    }
    case SET_EMPLOYEE_SOCIALMEDIA: {
      return { ...state, socialMedia: payload };
    }
    default: {
      return state;
    }
  }
};
