import { employerDetailProps, employeeDetailProps } from "../../interfaces";
import {
  LOAD_EMPLOYEE_LIST,
  LOAD_EMPLOYER_LIST,
  SET_EMPLOYEE_LIST,
  SET_EMPLOYER_LIST,
} from "./types";

export const loadEmployeeList = (payload?: any) => {
  return {
    type: LOAD_EMPLOYEE_LIST,
    payload,
  };
};
export const setEmployeeList = (payload: employeeDetailProps[]) => {
  return {
    type: SET_EMPLOYEE_LIST,
    payload,
  };
};

export const loadEmployerList = (payload?: any) => {
  return {
    type: LOAD_EMPLOYER_LIST,
    payload,
  };
};

export const setEmployerList = (payload: employerDetailProps[]) => {
  return {
    type: SET_EMPLOYER_LIST,
    payload,
  };
};
