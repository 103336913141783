import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import Typography from "@mui/material/Typography";
import { Copyright } from "../copyRight";
import {
  deleteAccount,
  forgotPassword,
  login,
  removeAuthUser,
  removeLoader,
  setAlert,
  setAuthUser,
  setLoader,
  store,
} from "src/store";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import {
  alertProps,
  authUserProps,
  deleteUserProps,
  loaderProps,
  loginProps,
} from "src/interfaces";
import {
  deleteAccountService,
  generateOTPService,
  loginService,
  validateUserService,
  verifyOTPService,
} from "src/services";
import { InputAdornment } from "@mui/material";
import CheckedOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
const Component = (props: {
  auth: authUserProps;
  handleSubmit: (payload: loginProps) => {};
  setAlert: (payload: alertProps) => void;
  setLoading: (payload: loaderProps) => void;
  removeLoading: () => void;
  handleProfileDelete: (payload: deleteUserProps) => void;
  signOut: () => void;
  setAuthToken: (payload: any) => void;
}) => {
  const {
    auth,
    handleSubmit,
    setLoading,
    removeLoading,
    setAlert,
    handleProfileDelete,
    signOut,
    setAuthToken,
  } = props;
  const [otp, setOtp] = React.useState<number>(0);
  const [model, setModel] = React.useState<{
    email: string;
    verified: boolean;
  }>({
    email: "",
    verified: false,
  });
  const onChange = (event: any) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const [showOTPField, setShowOTPField] = React.useState<boolean>(false);
  const [isVerified, setIsVerified] = React.useState<boolean>(false);

  const onGenerateOTP = async () => {
    setLoader({ transparent: true, visible: true });
    const response = await validateUserService({
      username: model?.email,
      role: "employee",
    });
    console.log(response);
    if (response === false) {
      return;
    }

    await generateOTPService(model.email)
      .then((res) => {
      
        setAlert({ text: res, type: "success" });
        setShowOTPField(true);
      })
      .catch((error) => {
        setAlert({ text: error?.message, type: "error" });
      })
      .finally(() => {
        removeLoader();
      });
  };

  const onVerifyOTP = async () => {
    setLoading({ transparent: true });
    try {
      const payload: any = {
        username: model?.email?.toString(),
        password: otp?.toString(),
        isOTP: true,
      };
      const response = await loginService(payload);
      console.log(response.status);
      if (response.token) {
        await deleteAccountService({
          userId: "test",
          verified: true,
          propToken: response.token,
        } as any);
      }
      setIsVerified(true);
      setModel({ ...model, verified: true });
      setAlert({ text: "Account deleted successfully", type: "success" });
    } catch (error) {
      setAlert({ text: `${error?.message}`, type: "error" });
    } finally {
      removeLoading();
    }

    // verifyOTPService(model.email, otp)
    //   .then((res) => {
    //     setIsVerified(true);
    //     setModel({ ...model, verified: true });
    //     setAlert({ text: res, type: "success" });
    //   })
    //   .catch((error) => {
    //     setAlert({ text: error?.message, type: "error" });
    //   })
    //   .finally(() => {
    //     removeLoader();
    //   });
  };

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    // onVerifyOTP()
    event.preventDefault();
    if (!isVerified) {
      return;
    }
    try {
      setLoader({ transparent: true, visible: true });
      const payload: any = {
        username: model?.email?.toString(),
        password: otp?.toString(),
        isOTP: true,
      };
      const response = await loginService(payload);
      console.log(response.status);
      if (response.token) {
        await deleteAccountService({
          userId: "test",
          verified: true,
          propToken: response.token,
        } as any);
      }
    } catch (error) {
      setAlert({
        text: `${error?.message}`,
        type: "error",
      });
    } finally {
      setIsVerified(true);
      removeLoader();
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100%" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(../../public/delete.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: 200,
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <NoAccountsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Delete account
          </Typography>
          <Box
            component="form"
            onSubmit={onSubmitForm}
            sx={{ m: 2, mt: 1, width: "95%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onChange}
              InputProps={{
                readOnly: showOTPField || model.verified,
                endAdornment: (
                  <InputAdornment position="end">
                    {model.verified ? (
                      <CheckedOutlinedIcon color="success" />
                    ) : model.email && !showOTPField ? (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          onGenerateOTP();
                        }}
                        sx={{
                          padding: "2px 8px",
                        }}
                      >
                        Verify
                      </Button>
                    ) : showOTPField ? (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          setModel({
                            ...model,
                            verified: false,
                          });
                          setShowOTPField(false);
                        }}
                        sx={{
                          padding: "2px 8px",
                        }}
                      >
                        Edit
                      </Button>
                    ) : null}
                  </InputAdornment>
                ),
              }}
            />
            {showOTPField && !model.verified && (
              <TextField
                margin="normal"
                fullWidth
                id="otp"
                label="Enter OTP"
                name="otp"
                aria-readonly={!showOTPField}
                autoComplete="otp"
                onChange={(event) => setOtp(parseInt(event.target.value))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {otp ? (
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => {
                            onVerifyOTP();
                          }}
                          sx={{
                            padding: "2px 8px",
                          }}
                        >
                          Delete Request
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => {
                            onGenerateOTP();
                          }}
                          sx={{
                            padding: "2px 8px",
                          }}
                        >
                          Resend
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {/* {model.verified ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                
              >
                Delete Request
              </Button>
            ) : null} */}

            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
              </Grid>
            </Grid>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (payload: any) => dispatch(login(payload)),
  setAlert: (payload: any) => dispatch(setAlert(payload)),
  setLoading: (payload: any) => dispatch(setLoader(payload)),
  removeLoading: () => dispatch(removeLoader()),
  handleProfileDelete: (payload: any) => dispatch(deleteAccount(payload)),
  signOut: () => dispatch(removeAuthUser()),
  setAuthToken: (payload: any) => dispatch(setAuthUser(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
