import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_APPLIED_JOBS, SET_JOB, SET_JOBS, SET_SAVED_JOBS } from "./types";
import { jobReducer } from "src/interfaces";

const initialeState: jobReducer = {
  all: [],
  saved: [],
  applied: [],
  detail: null,
};
export const jobs = (
  state: jobReducer = initialeState,
  action: PayloadAction<any> = null
): jobReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_JOBS: {
      return { ...state, all: payload };
    }
    case SET_JOB: {
      return { ...state, detail: payload };
    }
    case SET_SAVED_JOBS: {
      return { ...state, saved: payload };
    }
    case SET_APPLIED_JOBS: {
      return { ...state, applied: payload };
    }
    default: {
      return state;
    }
  }
};
