import { call, put, takeLatest } from "redux-saga/effects";
import {
  changePasswordProps,
  deleteUserProps,
  employerRegisterProps,
  loginProps,
  registerProps,
  resetPasswordProps,
  userValidationProps,
} from "../../interfaces";
import {
  HANDLE_LOGIN,
  HANDLE_REGISTER,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_RESET_PASSWORD,
  HANDLE_CHANGE_PASSWORD,
  HANDLE_EMPLOYER_REGISTER,
  HANDLE_USER_VALIDATION,
  HANDLE_DELETE_ACCOUNT,
} from "./types";
import {
  changePasswordService,
  employerRegisterService,
  forgotPasswordService,
  loginService,
  validateUserService,
  registerService,
  resetPasswordService,
  deleteAccountService,
} from "../../services";
import { removeAuthUser, setAuthUser } from "./actions";
import { setAlert } from "../alert";
import { setCurrentRoute } from "../router";
import { removeLoader, setLoader } from "../loader";

function* handleLogin({ payload }: { payload: loginProps }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(loginService, payload);
    yield put(setAuthUser({ ...responseData, isLoggedIn: true }));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUserValidation({
  payload,
}: {
  payload: userValidationProps;
}): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(validateUserService, payload);
    yield put(setAuthUser({ ...responseData, isLoggedIn: true }));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleRegister({
  payload,
}: {
  payload: registerProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(registerService, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(
      setCurrentRoute({ path: "/account/login", params: payload.email })
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleEmployerRegister({
  payload,
}: {
  payload: employerRegisterProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(employerRegisterService, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(
      setCurrentRoute({ path: "/account/login", params: payload.email })
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleForgotPassword({
  payload,
}: {
  payload: string;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(forgotPasswordService, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(
      setCurrentRoute({ path: "/account/verify-otp", params: payload })
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteAccount({
  payload,
}: {
  payload: deleteUserProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(deleteAccountService, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(removeAuthUser());
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleResetPassword({
  payload,
}: {
  payload: resetPasswordProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(resetPasswordService, payload);
    yield put(setAlert({ text: responseData, type: "success" }));
    yield put(
      setCurrentRoute({ path: "/account/login", params: payload.username })
    );
  } catch (error: any) {
    yield put(
      setCurrentRoute({
        path: "/account/forgot-password",
        params: payload.username,
      })
    );
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleChangePassword({
  payload,
}: {
  payload: changePasswordProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(changePasswordService, payload);
    payload?.callback && payload.callback();
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* authSaga() {
  yield takeLatest<any>(HANDLE_LOGIN, handleLogin);
  yield takeLatest<any>(HANDLE_USER_VALIDATION, handleUserValidation);
  yield takeLatest<any>(HANDLE_REGISTER, handleRegister);
  yield takeLatest<any>(HANDLE_EMPLOYER_REGISTER, handleEmployerRegister);
  yield takeLatest<any>(HANDLE_FORGOT_PASSWORD, handleForgotPassword);
  yield takeLatest<any>(HANDLE_DELETE_ACCOUNT, handleDeleteAccount);
  yield takeLatest<any>(HANDLE_RESET_PASSWORD, handleResetPassword);
  yield takeLatest<any>(HANDLE_CHANGE_PASSWORD, handleChangePassword);
}
