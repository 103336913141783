import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_COUNTRIES, SET_STATES, SET_CITIES } from "./types";
import { locationReducer } from "../../interfaces";

const initialeState: locationReducer = {
  cities: [],
  states: [],
  countries: [],
};

export const locations = (
  state = initialeState,
  action: PayloadAction<any[]> = null
): locationReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_COUNTRIES: {
      return { ...state, countries: payload };
    }
    case SET_STATES: {
      return { ...state, states: payload };
    }
    case SET_CITIES: {
      return { ...state, cities: payload };
    }
    default: {
      return state;
    }
  }
};
