import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";

import DashboardLayout from "src/layouts/DashboardLayout";
import BaseLayout from "./layouts/BaseLayout";
import EmployeeLayout from "./layouts/EmployeeLayout";
import DeleteAccount from "./views/authentication/deleteAccount";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Authentications

const Login = Loader(lazy(() => import("src/views/authentication/login")));
const ExternalLoginHandler = Loader(
  lazy(() => import("src/views/authentication/externalLoginHandler"))
);
const Register = Loader(
  lazy(() => import("src/views/authentication/register"))
);
const EmployerRegister = Loader(
  lazy(() => import("src/views/authentication/employerRegister"))
);
const ForgotPassword = Loader(
  lazy(() => import("src/views/authentication/forgotPassword"))
);
const ResetPassword = Loader(
  lazy(() => import("src/views/authentication/resetPassword"))
);
const ChangePassword = Loader(
  lazy(() => import("src/views/authentication/changePassword"))
);
const VerifyOTP = Loader(
  lazy(() => import("src/views/authentication/verifyOTP"))
);

// Admin

const Employees = Loader(lazy(() => import("src/views/admin/employees")));

const Employers = Loader(lazy(() => import("src/views/admin/employers")));

const JobPosts = Loader(lazy(() => import("src/views/admin/jobs")));

// Dashboard

const Dashboard = Loader(lazy(() => import("src/views/dashboards")));

// Employer //

const EmployerAccount = Loader(
  lazy(() => import("src/views/employer/account"))
);

const JobSeekers = Loader(lazy(() => import("src/views/employer/employees")));

// Employee //

const Home = Loader(lazy(() => import("src/views/home")));
const TermsOfUse = Loader(lazy(() => import("src/views/termsOfUse")));
const PrivacyPolicy = Loader(
  lazy(() => import("src/views/authentication/privacyPolicy"))
);

const Jobs = Loader(lazy(() => import("src/views/employee/jobs/JobList")));
const JobDetail = Loader(
  lazy(() => import("src/views/employee/jobs/JobDetail"))
);
const Services = Loader(lazy(() => import("src/views/employee/services")));
const EmployeeAccount = Loader(
  lazy(() => import("src/views/employee/account/settings"))
);
const EmployeeProfile = Loader(
  lazy(() => import("src/views/employee/account/profile"))
);
const PdfResume = Loader(lazy(() => import("src/views/employee/pdfResume")));

// Resume Templates //

const ResumeTemplate = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume"))
);
const ResumeTemplate1 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume1"))
);
const ResumeTemplate2 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume2"))
);
const ResumeTemplate3 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume3"))
);
const ResumeTemplate4 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume4"))
);
const ResumeTemplate5 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume5"))
);
const ResumeTemplate6 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume6"))
);
const ResumeTemplate7 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume7"))
);
const ResumeTemplate8 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume8"))
);
const ResumeTemplate9 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume9"))
);
const ResumeTemplate10 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume10"))
);
const ResumeTemplate11 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume11"))
);
const ResumeTemplate12 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume12"))
);
const ResumeTemplate13 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume13"))
);
const ResumeTemplate14 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume14"))
);
const ResumeTemplate15 = Loader(
  lazy(() => import("src/views/resumeTemplates/pdfResume15"))
);

const routes = (role?: string, isLoggedIn?: boolean): RouteObject[] => {
  let routesList: RouteObject[] = [];
  if (!isLoggedIn || role === "Employee") {
    routesList.push(
      {
        path: "",
        element: <EmployeeLayout />,
        children: [
          {
            path: "*",
            element: <Navigate to="" replace />,
          },
          {
            path: "",
            element: <Home />,
          },

          {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "/terms-of-use",
            element: <TermsOfUse />,
          },

          {
            path: "jobs",
            children: [
              {
                path: "*",
                element: <Navigate to="" replace />,
              },
              {
                path: "",
                element: <Jobs />,
              },
              {
                path: "detail/:id",
                element: <JobDetail />,
              },
            ],
          },
          {
            path: "services",
            element: <Services />,
          },
        ],
      },
      {
        path: "templates",
        element: <BaseLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="r1" replace />,
          },
          {
            path: "r1",
            element: <ResumeTemplate1 />,
          },
          {
            path: "r2",
            element: <ResumeTemplate2 />,
          },
          {
            path: "r3",
            element: <ResumeTemplate3 />,
          },

          {
            path: "r5",
            element: <ResumeTemplate5 />,
          },
          {
            path: "r6",
            element: <ResumeTemplate6 />,
          },
          {
            path: "r7",
            element: <ResumeTemplate7 />,
          },

          {
            path: "r8",
            element: <ResumeTemplate8 />,
          },
          {
            path: "r9",
            element: <ResumeTemplate9 />,
          },
          {
            path: "r10",
            element: <ResumeTemplate10 />,
          },

          {
            path: "r11",
            element: <ResumeTemplate11 />,
          },

          {
            path: "r12",
            element: <ResumeTemplate12 />,
          },
          {
            path: "r13",
            element: <ResumeTemplate13 />,
          },
          {
            path: "r14",
            element: <ResumeTemplate14 />,
          },
          {
            path: "r15",
            element: <ResumeTemplate15 />,
          },
          {
            path: "r16",
            element: <ResumeTemplate />,
          },
        ],
      }
    );
  }
  if (isLoggedIn) {
    if (role !== "Employee") {
      routesList.push(
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
        {
          path: "account",
          element: <DashboardLayout />,
          children: [
            {
              path: "change-password",
              element: <ChangePassword />,
            },
            {
              path: "settings",
              element: <EmployerAccount />,
            },
          ],
        }
      );
    }
    if (role === "Admin") {
      routesList.push({
        path: "",
        element: <DashboardLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="dashboard" replace />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "users",
            children: [
              {
                path: "employees",
                element: <Employees />,
              },
              {
                path: "employers",
                element: <Employers />,
              },
            ],
          },
          {
            path: "manage",
            children: [
              {
                path: "job-posts",
                element: <JobPosts />,
              },
            ],
          },
        ],
      });
    }
    if (role === "Employer") {
      routesList.push({
        path: "",
        element: <DashboardLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="dashboard" replace />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "manage",
            children: [
              {
                path: "job-seekers",
                element: <JobSeekers />,
              },
            ],
          },
        ],
      });
    } else {
      routesList.push(
        {
          path: "account",
          children: [
            {
              path: "resume",
              element: <PdfResume />,
            },
          ],
        },
        {
          path: "*",
          element: <EmployeeLayout />,
          children: [
            {
              path: "account-setting",
              children: [
                {
                  path: "profile",
                  element: <EmployeeProfile />,
                },
                {
                  path: "change-password",
                  element: <ChangePassword />,
                },
                {
                  path: "settings",
                  element: <EmployeeAccount />,
                },
              ],
            },
          ],
        }
      );
    }
  } else {
    routesList.push({
      path: "account",
      element: <BaseLayout />,
      children: [
        // {
        //   path: "",
        //   element: <Navigate to="login" replace />,
        // },
        {
          path: "login",
          element: <Login />,
        },
        // {
        //   path: "external-login",
        //   element: <ExternalLoginHandler />,
        // },
        // {
        //   path: "forgot-password",
        //   element: <ForgotPassword />,
        // },
        {
          path: "delete",
          element: <DeleteAccount />,
        },
        // {
        //   path: "reset-password",
        //   element: <ResetPassword />,
        // },
        // {
        //   path: "verify-otp",
        //   element: <VerifyOTP />,
        // },
        // {
        //   path: "register",
        //   element: <Register />,
        // },
        // {
        //   path: "employer-register",
        //   element: <EmployerRegister />,
        // },
      ],
    });
  }
  return routesList;
};

export default routes;
