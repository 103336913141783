import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_ALL_OPTIONS,
  LOAD_BOARD_UNIVERSITY_OPTIONS,
  LOAD_COURSE_OPTIONS,
  LOAD_JOB_DEPARTMENT_OPTIONS,
  LOAD_JOB_ROLE_CATEGORY_OPTIONS,
  LOAD_JOB_ROLE_OPTIONS,
  LOAD_LANGUAGE_OPTIONS,
  LOAD_QUALIFICATION_OPTIONS,
  LOAD_SKILL_OPTIONS,
  LOAD_SPECIALIZATION_OPTIONS,
} from "./types";
import {
  fetchAllOptionsService,
  fetchBoardUniversityOptionsService,
  fetchCourseSpecializationOptionsService,
  fetchJobDepartmentOptionsService,
  fetchJobRoleCategoryOptionsService,
  fetchJobRoleOptionsService,
  fetchLanguageOptionsService,
  fetchQualificationCourseOptionsService,
  fetchQualificationOptionsService,
  fetchSkillOptionsService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import {
  setAllOptions,
  setBoardUniversityOptions,
  setCourseOptions,
  setJobDepartmentOptions,
  setJobRoleCategoryOptions,
  setJobRoleOptions,
  setLanguageOptions,
  setQualificationOptions,
  setSkillOptions,
  setSpecializationOptions,
} from "./actions";

// function* handleLoadAllOptions(): Generator<any> {
//   try {
//     yield put(setLoader({ transparent: true }));
//     const responseData: any = yield call(fetchAllOptionsService);
//     yield put(setAllOptions(responseData));
//   } catch (error:any) {
//     yield put(setAlert({ text: error?.message, type: "error" }));
//   }
//   yield put(removeLoader());
// }

function* handleLoadSkillOptions(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchSkillOptionsService);
    yield put(setSkillOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadLanguageOptions(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchLanguageOptionsService);
    yield put(setLanguageOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadQualificationOptions(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchQualificationOptionsService);
    yield put(setQualificationOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadCourseOptions({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchQualificationCourseOptionsService,
      payload?.id
    );
    yield put(setCourseOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadSpecializationOptions({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchCourseSpecializationOptionsService,
      payload?.id
    );
    yield put(setSpecializationOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadJobDepartmentOptions(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchJobDepartmentOptionsService);
    yield put(setJobDepartmentOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadJobRoleCategoryOptions({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchJobRoleCategoryOptionsService,
      payload?.id
    );
    yield put(setJobRoleCategoryOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadJobRoleOptions({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchJobRoleOptionsService,
      payload?.id
    );
    yield put(setJobRoleOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadBoardUniversityOptions({ payload }: any): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchBoardUniversityOptionsService,
      payload?.search
    );
    yield put(setBoardUniversityOptions(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* optionsSaga() {
  // yield takeLatest<any>(LOAD_ALL_OPTIONS, handleLoadAllOptions);
  yield takeLatest<any>(LOAD_SKILL_OPTIONS, handleLoadSkillOptions);
  yield takeLatest<any>(LOAD_LANGUAGE_OPTIONS, handleLoadLanguageOptions);
  yield takeLatest<any>(
    LOAD_QUALIFICATION_OPTIONS,
    handleLoadQualificationOptions
  );
  yield takeLatest<any>(LOAD_COURSE_OPTIONS, handleLoadCourseOptions);
  yield takeLatest<any>(
    LOAD_SPECIALIZATION_OPTIONS,
    handleLoadSpecializationOptions
  );
  yield takeLatest<any>(
    LOAD_JOB_DEPARTMENT_OPTIONS,
    handleLoadJobDepartmentOptions
  );
  yield takeLatest<any>(
    LOAD_JOB_ROLE_CATEGORY_OPTIONS,
    handleLoadJobRoleCategoryOptions
  );
  yield takeLatest<any>(LOAD_JOB_ROLE_OPTIONS, handleLoadJobRoleOptions);
  yield takeLatest<any>(
    LOAD_BOARD_UNIVERSITY_OPTIONS,
    handleLoadBoardUniversityOptions
  );
}
