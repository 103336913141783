export const LOAD_ALL_OPTIONS = "LOAD_ALL_OPTIONS"
export const SET_ALL_OPTIONS = "SET_ALL_OPTIONS"
export const LOAD_SKILL_OPTIONS = "LOAD_SKILL_OPTIONS"
export const SET_SKILL_OPTIONS = "SET_SKILL_OPTIONS"
export const LOAD_LANGUAGE_OPTIONS = "LOAD_LANGUAGE_OPTIONS"
export const SET_LANGUAGE_OPTIONS = "SET_LANGUAGE_OPTIONS"
export const LOAD_QUALIFICATION_OPTIONS = "LOAD_QUALIFICATION_OPTIONS"
export const SET_QUALIFICATION_OPTIONS = "SET_QUALIFICATION_OPTIONS"
export const LOAD_COURSE_OPTIONS = "LOAD_COURSE_OPTIONS"
export const SET_COURSE_OPTIONS = "SET_COURSE_OPTIONS"
export const LOAD_SPECIALIZATION_OPTIONS = "LOAD_SPECIALIZATION_OPTIONS"
export const SET_SPECIALIZATION_OPTIONS = "SET_SPECIALIZATION_OPTIONS"
export const LOAD_JOB_DEPARTMENT_OPTIONS = "LOAD_JOB_DEPARTMENT_OPTIONS"
export const SET_JOB_DEPARTMENT_OPTIONS = "SET_JOB_DEPARTMENT_OPTIONS"
export const LOAD_JOB_ROLE_CATEGORY_OPTIONS = "LOAD_JOB_ROLE_CATEGORY_OPTIONS"
export const SET_JOB_ROLE_CATEGORY_OPTIONS = "SET_JOB_ROLE_CATEGORY_OPTIONS"
export const LOAD_JOB_ROLE_OPTIONS = "LOAD_JOB_ROLE_OPTIONS"
export const SET_JOB_ROLE_OPTIONS = "SET_JOB_ROLE_OPTIONS"
export const LOAD_BOARD_UNIVERSITY_OPTIONS = "LOAD_BOARD_UNIVERSITY_OPTIONS"
export const SET_BOARD_UNIVERSITY_OPTIONS = "SET_BOARD_UNIVERSITY_OPTIONS"
