import { templateProps } from "../../interfaces";
import { SET_TEMPLATES, LOAD_TEMPLATES } from "./types";

export const loadTemplates = (payload?: any) => {
  return {
    type: LOAD_TEMPLATES,
    payload,
  };
};

export const setTemplates = (payload: templateProps[]) => {
  return {
    type: SET_TEMPLATES,
    payload,
  };
};
