import { all } from "redux-saga/effects";
import {
  authSaga,
  employerSaga,
  employeeSaga,
  locationSaga,
  optionsSaga,
  jobSaga,
  adminSaga,
  templateSaga,
} from "./";

export default function* rootSaga() {
  yield all([
    authSaga(),
    adminSaga(),
    employerSaga(),
    employeeSaga(),
    locationSaga(),
    optionsSaga(),
    templateSaga(),
    jobSaga(),
  ]);
}
