import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SET_ALL_OPTIONS,
  SET_SKILL_OPTIONS,
  SET_COURSE_OPTIONS,
  SET_JOB_DEPARTMENT_OPTIONS,
  SET_JOB_ROLE_CATEGORY_OPTIONS,
  SET_JOB_ROLE_OPTIONS,
  SET_QUALIFICATION_OPTIONS,
  SET_SPECIALIZATION_OPTIONS,
  SET_BOARD_UNIVERSITY_OPTIONS,
  SET_LANGUAGE_OPTIONS,
} from "./types";
import { optionReducer } from "src/interfaces";

const initialeState: optionReducer = {
  skills: [],
  languages: [],
  qualifications: [],
  courses: [],
  specializations: [],
  jobDepartments: [],
  jobRoleCategories: [],
  jobRoles: [],
  boardUniversities: [],
};

export const options = (
  state: optionReducer = initialeState,
  action: PayloadAction<any> = null
): optionReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_ALL_OPTIONS: {
      return { ...state, ...payload };
    }
    case SET_SKILL_OPTIONS: {
      return { ...state, skills: [...state?.skills, ...payload] };
    }
    case SET_LANGUAGE_OPTIONS: {
      return { ...state, languages: [...state?.languages, ...payload] };
    }
    case SET_QUALIFICATION_OPTIONS: {
      return {
        ...state,
        qualifications: [...payload],
      };
    }
    case SET_COURSE_OPTIONS: {
      return { ...state, courses: [...payload] };
    }
    case SET_SPECIALIZATION_OPTIONS: {
      return {
        ...state,
        specializations: [...payload],
      };
    }
    case SET_JOB_DEPARTMENT_OPTIONS: {
      return {
        ...state,
        jobDepartments: [...payload],
      };
    }
    case SET_JOB_ROLE_CATEGORY_OPTIONS: {
      return {
        ...state,
        jobRoleCategories: [...payload],
      };
    }
    case SET_JOB_ROLE_OPTIONS: {
      return { ...state, jobRoles: [...payload] };
    }
    case SET_BOARD_UNIVERSITY_OPTIONS: {
      return { ...state, boardUniversities: [...payload] };
    }
    default: {
      return state;
    }
  }
};
