import { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import router from "src/router";
import { connect } from "react-redux";
import { CssBaseline } from "@mui/material";
import { authUserProps, routeProps } from "./interfaces";
import Snackbar from "./components/Snackbar";
import { removeRoute } from "./store";
import Spinner from "./components/Spinner";
import { appInitializer } from "./helpers";

function AppContent({
  auth,
  route,
  removeRoute,
}: {
  auth: authUserProps;
  route: routeProps;
  removeRoute: Function;
}) {
  const content = useRoutes(router(auth?.role, auth?.isLoggedIn));
  const navigate = useNavigate();

  useEffect(() => {
    if (route?.path) {
      navigate(route?.path, { state: route.params });
      removeRoute();
    }
  }, [navigate, removeRoute, route]);

  useEffect(() => {
    appInitializer();
  }, []);

  return (
    <>
      <Spinner />
      <Snackbar />
      <CssBaseline />
      {content}
    </>
  );
}
const mapStateToProps = ({ authUser, route }) => ({ auth: authUser, route });
const mapDispatchToProps = (dispatch) => ({
  removeRoute: () => dispatch(removeRoute()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
