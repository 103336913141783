import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_TEMPLATES } from "./types";
import { templateProps } from "src/interfaces";

const initialeState: templateProps[] = [];
export const templates = (
  state: templateProps[] = initialeState,
  action: PayloadAction<any> = null
): templateProps[] => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_TEMPLATES: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
