import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_AUTH_USER, REMOVE_AUTH_USER } from "./types";
import { authUserProps } from "../../interfaces";

const initialeState: authUserProps = {
  fullName: "",
  email: "",
  phoneNumber: "",
  avatar: "",
  username: "",
  role: "",
  token: "",
  isLoggedIn: false,
};
export const authUser = (
  state = initialeState,
  action: PayloadAction<authUserProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_AUTH_USER: {
      return { ...state, ...payload };
    }
    case REMOVE_AUTH_USER: {
      return initialeState;
    }
    default: {
      return state;
    }
  }
};
