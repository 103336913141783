import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_EMPLOYER_DETAIL } from "./types";
import { employerReducer } from "../../interfaces";

const initialeState: employerReducer = {
  detail: null,
};
export const employer = (
  state = initialeState,
  action: PayloadAction<any> = null
): employerReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_EMPLOYER_DETAIL: {
      return { ...state, detail: payload };
    }
    default: {
      return state;
    }
  }
};
