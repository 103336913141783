import {
  changePasswordProps,
  deleteUserProps,
  employerRegisterProps,
  loginProps,
  registerProps,
  resetPasswordProps,
} from "../interfaces";
import http from "./http";

const controller: string = "Auth/";
export const loginService = async (payload: loginProps) => {
  try {
    const responseData = await http.Post(controller + "Login", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const validateUserService = async (payload: {
  username: string;
  role: string;
}) => {
  try {
    const responseData = await http.Post(controller + "CheckIsValidUser", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const generateOTPService = async (email: string) => {
  try {
    const responseData = await http.Post(controller + "VerificationOTP", {
      email,
    });
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const verifyOTPService = async (username: string, otp: number) => {
  try {
    const responseData = await http.Post(controller + "VerifyOTP", {
      username,
      otp,
    });
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const forgotPasswordService = async (userName: string) => {
  try {
    const responseData = await http.Post(controller + "ForgotPassword", {
      userName,
    });
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const deleteAccountService = async (payload: deleteUserProps) => {
  try {
    const responseData = await http.Delete(controller + "DeleteUser",  payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const resetPasswordService = async (payload: resetPasswordProps) => {
  try {
    const responseData = await http.Post(controller + "ResetPassword", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const changePasswordService = async (payload: changePasswordProps) => {
  try {
    const responseData = await http.Post(controller + "ChangePassword", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeAvatarService = async (payload: { file: File }) => {
  try {
    const responseData = await http.Post(
      controller + "UploadAvatar",
      payload,
      "multipart/form-data"
    );
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const registerService = async (payload: registerProps) => {
  try {
    const responseData = await http.Post(controller + "Register", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};

export const employerRegisterService = async (
  payload: employerRegisterProps
) => {
  try {
    const responseData = await http.Post(controller + "EmployerRegister", payload);
    return responseData
  } catch (error: any) {
    throw error;
  }
};
