export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const convertToForm = (payload: any): FormData => {
  const keys = Object.keys(payload);
  let formData = new FormData();
  keys.map((key) => {
    formData.append(key, payload[key]);
  });
  return formData;
};

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
});

export const convertBlobToDataURL = async (blobUrl: string) => {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting Blob to Data URL:', error);
    return null;
  }
};