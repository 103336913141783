import { convertToForm } from "src/helpers";
import { removeAuthUser, store } from "src/store";

// const baseUrl = "http://localhost/api/";
export const baseUrl = process.env.REACT_APP_BASE_URL;
const baseApiUrl = baseUrl + "/api/";

const errorHandler = async (response: any) => {
  if (response.status == 200) {
    const data = await response.json();
    return data;
  } else if (response.status == 201) {
    const data = await response.json();
    throw new Error(data);
  } else if (response.status == 401) {
    store.dispatch(removeAuthUser());
    throw new Error("You are not a valid user");
  } else if (response?.status == 403) {
    throw new Error("You don't have permission to access this feature");
  } else {
    throw new Error("Server error");
  }
};

const Get = (url, propToken = "") => {
  const token = store.getState().authUser?.token || propToken;
  return fetch(baseApiUrl + url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};
const Post = (
  url,
  payload = {},
  payloadType: "application/json" | "multipart/form-data" = "application/json"
) => {
  const token = store.getState().authUser?.token;
  if (payloadType === "multipart/form-data") {
    return fetch(baseApiUrl + url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: convertToForm(payload),
    }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        await errorHandler(response);
      }
    });
  }
  return fetch(baseApiUrl + url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};
const Patch = (url, payload = {}) => {
  const token = store.getState().authUser?.token;
  return fetch(baseApiUrl + url, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};
const Delete = (url, payload = { propToken: ""  as any}) => {
  const token = store.getState().authUser?.token || (payload?.propToken as any);
  console.log("token", token);

  return fetch(baseApiUrl + url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

const http = { Get, Post, Patch, Delete };
export default http;
