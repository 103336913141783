import {
  skillOptionProps,
  optionReducer,
  qualificationOptionProps,
  courseOptionProps,
  specializationOptionProps,
  jobDepartmentOptionProps,
  jobRoleCategoryOptionProps,
  jobRoleOptionProps,
  boardUniversityOptionProps,
  languageOptionProps,
} from "../../interfaces";
import {
  SET_ALL_OPTIONS,
  SET_SKILL_OPTIONS,
  SET_COURSE_OPTIONS,
  SET_JOB_DEPARTMENT_OPTIONS,
  SET_JOB_ROLE_CATEGORY_OPTIONS,
  SET_JOB_ROLE_OPTIONS,
  SET_QUALIFICATION_OPTIONS,
  SET_SPECIALIZATION_OPTIONS,
  SET_BOARD_UNIVERSITY_OPTIONS,
  LOAD_ALL_OPTIONS,
  LOAD_SKILL_OPTIONS,
  LOAD_QUALIFICATION_OPTIONS,
  LOAD_COURSE_OPTIONS,
  LOAD_SPECIALIZATION_OPTIONS,
  LOAD_JOB_ROLE_CATEGORY_OPTIONS,
  LOAD_JOB_DEPARTMENT_OPTIONS,
  LOAD_JOB_ROLE_OPTIONS,
  LOAD_BOARD_UNIVERSITY_OPTIONS,
  LOAD_LANGUAGE_OPTIONS,
  SET_LANGUAGE_OPTIONS,
} from "./types";

export const loadAllOptions = () => {
  return {
    type: LOAD_ALL_OPTIONS,
  };
};
export const setAllOptions = (payload: optionReducer) => {
  return {
    type: SET_ALL_OPTIONS,
    payload,
  };
};

export const loadSkillOptions = () => {
  return {
    type: LOAD_SKILL_OPTIONS,
  };
};
export const setSkillOptions = (payload: skillOptionProps) => {
  return {
    type: SET_SKILL_OPTIONS,
    payload,
  };
};

export const loadLanguageOptions = () => {
  return {
    type: LOAD_LANGUAGE_OPTIONS,
  };
};
export const setLanguageOptions = (payload: languageOptionProps) => {
  return {
    type: SET_LANGUAGE_OPTIONS,
    payload,
  };
};

export const loadQualificationOptions = () => {
  return {
    type: LOAD_QUALIFICATION_OPTIONS,
  };
};
export const setQualificationOptions = (payload: qualificationOptionProps) => {
  return {
    type: SET_QUALIFICATION_OPTIONS,
    payload,
  };
};

export const loadCourseOptions = (payload: { id: number }) => {
  return {
    type: LOAD_COURSE_OPTIONS,
    payload,
  };
};
export const setCourseOptions = (payload: courseOptionProps) => {
  return {
    type: SET_COURSE_OPTIONS,
    payload,
  };
};

export const loadSpecializationOptions = (payload: { id: number }) => {
  return {
    type: LOAD_SPECIALIZATION_OPTIONS,
    payload,
  };
};
export const setSpecializationOptions = (
  payload: specializationOptionProps
) => {
  return {
    type: SET_SPECIALIZATION_OPTIONS,
    payload,
  };
};

export const loadJobDepartmentOptions = () => {
  return {
    type: LOAD_JOB_DEPARTMENT_OPTIONS,
  };
};
export const setJobDepartmentOptions = (payload: jobDepartmentOptionProps) => {
  return {
    type: SET_JOB_DEPARTMENT_OPTIONS,
    payload,
  };
};

export const loadJobRoleCategoryOptions = (payload: {
  id: number | number[];
}) => {
  return {
    type: LOAD_JOB_ROLE_CATEGORY_OPTIONS,
    payload,
  };
};
export const setJobRoleCategoryOptions = (
  payload: jobRoleCategoryOptionProps
) => {
  return {
    type: SET_JOB_ROLE_CATEGORY_OPTIONS,
    payload,
  };
};

export const loadJobRoleOptions = (payload: { id: number | number[] }) => {
  return {
    type: LOAD_JOB_ROLE_OPTIONS,
    payload,
  };
};
export const setJobRoleOptions = (payload: jobRoleOptionProps) => {
  return {
    type: SET_JOB_ROLE_OPTIONS,
    payload,
  };
};

export const loadBoardUniversityOptions = (payload: { search: string }) => {
  return {
    type: LOAD_BOARD_UNIVERSITY_OPTIONS,
    payload,
  };
};
export const setBoardUniversityOptions = (
  payload: boardUniversityOptionProps
) => {
  return {
    type: SET_BOARD_UNIVERSITY_OPTIONS,
    payload,
  };
};
