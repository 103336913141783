import http from "./http";

const controller: string = "Resume/";

export const fetchTemplatesService = async () => {
  try {
    const responseData = await http.Get(controller + "Templates");
    return responseData
  } catch (error: any) {
    throw error;
  }
};