import http from "./http";

const controller: string = "Admin/";

export const fetchEmployeesService = async (payload = {}) => {
  try {
    const responseData = await http.Patch(controller + "GetEmployees", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const fetchEmployersService = async (payload = {}) => {
  try {
    const responseData = await http.Patch(controller + "GetEmployers", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

