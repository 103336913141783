import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_EMPLOYEE_DETAIL,
  LOAD_EMPLOYEE_SKILLS,
  HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL,
  HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS,
  HANDLE_DELETE_EMPLOYEE_SKILL,
  LOAD_EMPLOYEE_ALL_DETAILS,
  LOAD_EMPLOYEE_PROJECTS,
  HANDLE_ADD_EMPLOYEE_PROJECT,
  HANDLE_DELETE_EMPLOYEE_PROJECT,
  HANDLE_UPDATE_EMPLOYEE_PROJECT,
  LOAD_EMPLOYEE_PREFERRED_LOCATIONS,
  HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS,
  HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION,
  LOAD_EMPLOYEE_QUALIFICATIONS,
  HANDLE_ADD_EMPLOYEE_QUALIFICATION,
  HANDLE_UPDATE_EMPLOYEE_QUALIFICATION,
  HANDLE_DELETE_EMPLOYEE_QUALIFICATION,
  HANDLE_UPDATE_EMPLOYEE_EXPERIENCE,
  HANDLE_ADD_EMPLOYEE_EXPERIENCE,
  LOAD_EMPLOYEE_EXPERIENCES,
  HANDLE_DELETE_EMPLOYEE_EXPERIENCE,
  LOAD_EMPLOYEE_RESUMES,
  HANDLE_ADD_EMPLOYEE_RESUME,
  HANDLE_DELETE_EMPLOYEE_RESUME,
  HANDLE_EMPLOYEE_PROFILE_AVATAR,
  LOAD_EMPLOYEE_LANGUAGES,
  HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES,
  HANDLE_DELETE_EMPLOYEE_LANGUAGE,
  LOAD_EMPLOYEE_CERTIFICATES,
  HANDLE_ADD_EMPLOYEE_CERTIFICATE,
  HANDLE_UPDATE_EMPLOYEE_CERTIFICATE,
  HANDLE_DELETE_EMPLOYEE_CERTIFICATE,
  LOAD_EMPLOYEE_SOCIALMEDIA,
  HANDLE_ADD_EMPLOYEE_SOCIALMEDIA,
  HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA,
  HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA,
} from "./types";
import {
  fetchEmployeeAllDetailService,
  fetchEmployeePersonalDetailService,
  addUpdateEmployeeSkillsService,
  fetchEmployeeProjectsService,
  fetchEmployeeSkillsService,
  deleteEmployeeSkillService,
  deleteEmployeeProjectService,
  addEmployeeProjectService,
  updateEmployeeProjectService,
  deleteEmployeePreferredLocationService,
  addUpdateEmployeePreferredLocationsService,
  fetchEmployeePreferredLocationsService,
  deleteEmployeeQualificationService,
  updateEmployeeQualificationService,
  addEmployeeQualificationService,
  fetchEmployeeQualificationsService,
  deleteEmployeeExperienceService,
  updateEmployeeExperienceService,
  addEmployeeExperienceService,
  fetchEmployeeExperiencesService,
  fetchEmployeeResumesService,
  addEmployeeResumeService,
  deleteEmployeeResumeService,
  addEmployeeAvatarService,
  addUpdateEmployeeDetailService,
  fetchEmployeeLanguagesService,
  addUpdateEmployeeLanguagesService,
  deleteEmployeeLanguageService,
  deleteEmployeeCertificateService,
  updateEmployeeCertificateService,
  addEmployeeCertificateService,
  fetchEmployeeCertificatesService,
  deleteEmployeeSocialMediaService,
  updateEmployeeSocialMediaService,
  addEmployeeSocialMediaService,
  fetchEmployeeSocialMediaService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import {
  setEmployeeAllDetail,
  setEmployeeCertificates,
  setEmployeeDetail,
  setEmployeeExperiences,
  setEmployeeLanguages,
  setEmployeePreferredLocations,
  setEmployeeProfileAvatar,
  setEmployeeProjects,
  setEmployeeQualifications,
  setEmployeeResumes,
  setEmployeeSkills,
  setEmployeeSocialMedia,
} from "./actions";
import {
  certificateProps,
  employeeDetailUpdateProps,
  experienceProps,
  projectProps,
  qualificationProps,
  resumeProps,
  skillProps,
  uploadResumeProps,
  preferredLocationProps,
  languageProps,
  socialMediaProps,
} from "src/interfaces";
import { setAuthUser } from "../auth";
import { setLanguageOptions, setSkillOptions, store } from "src/store";

function* handleLoadEmployeeAllDetail({payload = ""}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeAllDetailService, payload);
    yield put(setEmployeeAllDetail(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeDetail(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeePersonalDetailService);
    yield put(setEmployeeDetail(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddUpdateEmployeeDetail({
  payload,
}: {
  payload: employeeDetailUpdateProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addUpdateEmployeeDetailService,
      payload
    );
    yield put(setAuthUser({ fullName: payload.fullName } as any));
    yield put(setEmployeeDetail(payload as any));
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddUpdateEmployeeProfileAvatar({
  payload,
}: {
  payload: { file: File };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(addEmployeeAvatarService, payload);
    yield put(setEmployeeProfileAvatar(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeResumes(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeResumesService);
    yield put(setEmployeeResumes(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeResume({
  payload,
}: {
  payload: uploadResumeProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(addEmployeeResumeService, payload);
    var resumes: resumeProps[] = store?.getState()?.employee?.resumes;
    yield put(setEmployeeResumes([...resumes, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeResume({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(deleteEmployeeResumeService, payload);
    var resumes: resumeProps[] = store?.getState()?.employee?.resumes;
    yield put(
      setEmployeeResumes(resumes?.filter((item) => item.id != payload?.id))
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeQualifications(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeQualificationsService);
    yield put(setEmployeeQualifications(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeQualification({
  payload,
}: {
  payload: qualificationProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addEmployeeQualificationService,
      payload
    );
    var qualifications: qualificationProps[] =
      store?.getState()?.employee?.qualifications;
    yield put(setEmployeeQualifications([...qualifications, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployeeQualification({
  payload,
}: {
  payload: qualificationProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      updateEmployeeQualificationService,
      payload
    );
    var qualifications: qualificationProps[] =
      store?.getState()?.employee?.qualifications;
    yield put(
      setEmployeeQualifications([
        ...qualifications?.filter((item) => item.id != payload?.id),
        responseData,
      ])
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeQualification({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeeQualificationService,
      payload
    );
    var qualifications: qualificationProps[] =
      store?.getState()?.employee?.qualifications;
    yield put(
      setEmployeeQualifications(
        qualifications?.filter((item) => item.id != payload?.id)
      )
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeSkills(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeSkillsService);
    yield put(setEmployeeSkills(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddUpdateEmployeeSkills({
  payload,
}: {
  payload: skillProps[];
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addUpdateEmployeeSkillsService,
      payload
    );
    yield put(setEmployeeSkills(responseData?.skills));
    yield put(setSkillOptions(responseData?.newSkillOptions));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeSkill({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(deleteEmployeeSkillService, payload);
    var skills: skillProps[] = store?.getState()?.employee?.skills;
    yield put(
      setEmployeeSkills(skills?.filter((item) => item.id != payload?.id))
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeLanguages(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeLanguagesService);
    yield put(setEmployeeLanguages(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddUpdateEmployeeLanguages({
  payload,
}: {
  payload: languageProps[];
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addUpdateEmployeeLanguagesService,
      payload
    );
    yield put(setEmployeeLanguages(responseData?.languages));
    yield put(setLanguageOptions(responseData?.newLanguageOptions));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeLanguage({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeeLanguageService,
      payload
    );
    var languages: languageProps[] = store?.getState()?.employee?.languages;
    yield put(
      setEmployeeLanguages(languages?.filter((item) => item.id != payload?.id))
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeExperiences(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeExperiencesService);
    yield put(setEmployeeExperiences(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeExperience({
  payload,
}: {
  payload: experienceProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(addEmployeeExperienceService, payload);
    var experiences: experienceProps[] =
      store?.getState()?.employee?.experiences;
    yield put(setEmployeeExperiences([...experiences, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployeeExperience({
  payload,
}: {
  payload: experienceProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      updateEmployeeExperienceService,
      payload
    );
    var experiences: experienceProps[] =
      store?.getState()?.employee?.experiences;
    yield put(
      setEmployeeExperiences([
        ...experiences?.filter((item) => item.id != payload?.id),
        responseData,
      ])
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeExperience({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeeExperienceService,
      payload
    );
    var experiences: experienceProps[] =
      store?.getState()?.employee?.experiences;
    yield put(
      setEmployeeExperiences(
        experiences?.filter((item) => item.id != payload?.id)
      )
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeCertificates(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeCertificatesService);
    yield put(setEmployeeCertificates(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeCertificate({
  payload,
}: {
  payload: certificateProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addEmployeeCertificateService,
      payload
    );
    var certificates: certificateProps[] =
      store?.getState()?.employee?.certificates;
    yield put(setEmployeeCertificates([...certificates, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployeeCertificate({
  payload,
}: {
  payload: certificateProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      updateEmployeeCertificateService,
      payload
    );
    var certificates: certificateProps[] =
      store?.getState()?.employee?.certificates;
    yield put(
      setEmployeeCertificates([
        ...certificates?.filter((item) => item.id != payload?.id),
        responseData,
      ])
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeCertificate({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeeCertificateService,
      payload
    );
    var certificates: certificateProps[] =
      store?.getState()?.employee?.certificates;
    yield put(
      setEmployeeCertificates(
        certificates?.filter((item) => item.id != payload?.id)
      )
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeProjects(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeProjectsService);
    yield put(setEmployeeProjects(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeProject({
  payload,
}: {
  payload: projectProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(addEmployeeProjectService, payload);
    var projects: projectProps[] = store?.getState()?.employee?.projects;
    yield put(setEmployeeProjects([...projects, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployeeProject({
  payload,
}: {
  payload: projectProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(updateEmployeeProjectService, payload);
    var projects: projectProps[] = store?.getState()?.employee?.projects;
    yield put(
      setEmployeeProjects([
        ...projects?.filter((item) => item.id != payload?.id),
        responseData,
      ])
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeProject({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(deleteEmployeeProjectService, payload);
    var projects: projectProps[] = store?.getState()?.employee?.projects;
    yield put(
      setEmployeeProjects(projects?.filter((item) => item.id != payload?.id))
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeePreferredLocations(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchEmployeePreferredLocationsService
    );
    yield put(setEmployeePreferredLocations(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddUpdateEmployeePreferredLocations({
  payload,
}: {
  payload: preferredLocationProps[];
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addUpdateEmployeePreferredLocationsService,
      payload
    );
    yield put(setEmployeePreferredLocations(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeePreferredLocation({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeePreferredLocationService,
      payload
    );
    var preferredLocations: preferredLocationProps[] =
      store?.getState()?.employee?.preferredLocations;
    yield put(
      setEmployeePreferredLocations(
        preferredLocations?.filter((item) => item.id != payload?.id)
      )
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadEmployeeSocialMedia(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployeeSocialMediaService);
    yield put(setEmployeeSocialMedia(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddEmployeeSocialMedia({
  payload,
}: {
  payload: socialMediaProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      addEmployeeSocialMediaService,
      payload
    );
    var socialMedia: socialMediaProps[] =
      store?.getState()?.employee?.socialMedia;
    yield put(setEmployeeSocialMedia([...socialMedia, responseData]));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployeeSocialMedia({
  payload,
}: {
  payload: socialMediaProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      updateEmployeeSocialMediaService,
      payload
    );
    var socialMedia: socialMediaProps[] =
      store?.getState()?.employee?.socialMedia;
    yield put(
      setEmployeeSocialMedia([
        ...socialMedia?.filter((item) => item.id != payload?.id),
        responseData,
      ])
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteEmployeeSocialMedia({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      deleteEmployeeSocialMediaService,
      payload
    );
    var socialMedia: socialMediaProps[] =
      store?.getState()?.employee?.socialMedia;
    yield put(
      setEmployeeSocialMedia(
        socialMedia?.filter((item) => item.id != payload?.id)
      )
    );
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* employeeSaga() {
  yield takeLatest<any>(LOAD_EMPLOYEE_ALL_DETAILS, handleLoadEmployeeAllDetail);
  yield takeLatest<any>(LOAD_EMPLOYEE_DETAIL, handleLoadEmployeeDetail);
  yield takeLatest<any>(
    HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL,
    handleAddUpdateEmployeeDetail
  );
  yield takeLatest<any>(
    HANDLE_EMPLOYEE_PROFILE_AVATAR,
    handleAddUpdateEmployeeProfileAvatar
  );

  yield takeLatest<any>(LOAD_EMPLOYEE_RESUMES, handleLoadEmployeeResumes);
  yield takeLatest<any>(HANDLE_ADD_EMPLOYEE_RESUME, handleAddEmployeeResume);
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_RESUME,
    handleDeleteEmployeeResume
  );

  yield takeLatest<any>(
    LOAD_EMPLOYEE_QUALIFICATIONS,
    handleLoadEmployeeQualifications
  );
  yield takeLatest<any>(
    HANDLE_ADD_EMPLOYEE_QUALIFICATION,
    handleAddEmployeeQualification
  );
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYEE_QUALIFICATION,
    handleUpdateEmployeeQualification
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_QUALIFICATION,
    handleDeleteEmployeeQualification
  );

  yield takeLatest<any>(LOAD_EMPLOYEE_SKILLS, handleLoadEmployeeSkills);
  yield takeLatest<any>(
    HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS,
    handleAddUpdateEmployeeSkills
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_SKILL,
    handleDeleteEmployeeSkill
  );

  yield takeLatest<any>(LOAD_EMPLOYEE_LANGUAGES, handleLoadEmployeeLanguages);
  yield takeLatest<any>(
    HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES,
    handleAddUpdateEmployeeLanguages
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_LANGUAGE,
    handleDeleteEmployeeLanguage
  );

  yield takeLatest<any>(
    LOAD_EMPLOYEE_EXPERIENCES,
    handleLoadEmployeeExperiences
  );
  yield takeLatest<any>(
    HANDLE_ADD_EMPLOYEE_EXPERIENCE,
    handleAddEmployeeExperience
  );
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYEE_EXPERIENCE,
    handleUpdateEmployeeExperience
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_EXPERIENCE,
    handleDeleteEmployeeExperience
  );

  yield takeLatest<any>(
    LOAD_EMPLOYEE_CERTIFICATES,
    handleLoadEmployeeCertificates
  );
  yield takeLatest<any>(
    HANDLE_ADD_EMPLOYEE_CERTIFICATE,
    handleAddEmployeeCertificate
  );
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYEE_CERTIFICATE,
    handleUpdateEmployeeCertificate
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_CERTIFICATE,
    handleDeleteEmployeeCertificate
  );

  yield takeLatest<any>(LOAD_EMPLOYEE_PROJECTS, handleLoadEmployeeProjects);
  yield takeLatest<any>(HANDLE_ADD_EMPLOYEE_PROJECT, handleAddEmployeeProject);
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYEE_PROJECT,
    handleUpdateEmployeeProject
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_PROJECT,
    handleDeleteEmployeeProject
  );

  yield takeLatest<any>(
    LOAD_EMPLOYEE_PREFERRED_LOCATIONS,
    handleLoadEmployeePreferredLocations
  );
  yield takeLatest<any>(
    HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS,
    handleAddUpdateEmployeePreferredLocations
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION,
    handleDeleteEmployeePreferredLocation
  );

  yield takeLatest<any>(
    LOAD_EMPLOYEE_SOCIALMEDIA,
    handleLoadEmployeeSocialMedia
  );
  yield takeLatest<any>(
    HANDLE_ADD_EMPLOYEE_SOCIALMEDIA,
    handleAddEmployeeSocialMedia
  );
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA,
    handleUpdateEmployeeSocialMedia
  );
  yield takeLatest<any>(
    HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA,
    handleDeleteEmployeeSocialMedia
  );
}
