import {
  certificateProps,
  employeeDetailProps,
  employeeDetailUpdateProps,
  employeeReducer,
  experienceProps,
  languageProps,
  preferredLocationProps,
  projectProps,
  qualificationProps,
  resumeProps,
  skillProps,
  socialMediaProps,
  uploadResumeProps,
} from "../../interfaces";
import {
  HANDLE_DELETE_EMPLOYEE_SKILL,
  HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL,
  LOAD_EMPLOYEE_ALL_DETAILS,
  LOAD_EMPLOYEE_DETAIL,
  LOAD_EMPLOYEE_SKILLS,
  HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS,
  SET_EMPLOYEE_ALL_DETAILS,
  SET_EMPLOYEE_DETAIL,
  SET_EMPLOYEE_PREFERRED_LOCATIONS,
  SET_EMPLOYEE_PROJECTS,
  SET_EMPLOYEE_QUALIFICATIONS,
  SET_EMPLOYEE_SKILLS,
  LOAD_EMPLOYEE_PROJECTS,
  HANDLE_ADD_EMPLOYEE_PROJECT,
  HANDLE_UPDATE_EMPLOYEE_PROJECT,
  HANDLE_DELETE_EMPLOYEE_PROJECT,
  LOAD_EMPLOYEE_PREFERRED_LOCATIONS,
  HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS,
  HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION,
  HANDLE_DELETE_EMPLOYEE_QUALIFICATION,
  HANDLE_UPDATE_EMPLOYEE_QUALIFICATION,
  HANDLE_ADD_EMPLOYEE_QUALIFICATION,
  LOAD_EMPLOYEE_QUALIFICATIONS,
  LOAD_EMPLOYEE_EXPERIENCES,
  HANDLE_ADD_EMPLOYEE_EXPERIENCE,
  HANDLE_UPDATE_EMPLOYEE_EXPERIENCE,
  HANDLE_DELETE_EMPLOYEE_EXPERIENCE,
  SET_EMPLOYEE_EXPERIENCES,
  LOAD_EMPLOYEE_RESUMES,
  HANDLE_ADD_EMPLOYEE_RESUME,
  HANDLE_DELETE_EMPLOYEE_RESUME,
  SET_EMPLOYEE_RESUMES,
  SET_EMPLOYEE_PROFILE_AVATAR,
  HANDLE_EMPLOYEE_PROFILE_AVATAR,
  LOAD_EMPLOYEE_LANGUAGES,
  HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES,
  HANDLE_DELETE_EMPLOYEE_LANGUAGE,
  SET_EMPLOYEE_LANGUAGES,
  LOAD_EMPLOYEE_CERTIFICATES,
  HANDLE_ADD_EMPLOYEE_CERTIFICATE,
  HANDLE_UPDATE_EMPLOYEE_CERTIFICATE,
  HANDLE_DELETE_EMPLOYEE_CERTIFICATE,
  SET_EMPLOYEE_CERTIFICATES,
  LOAD_EMPLOYEE_SOCIALMEDIA,
  HANDLE_ADD_EMPLOYEE_SOCIALMEDIA,
  HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA,
  HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA,
  SET_EMPLOYEE_SOCIALMEDIA,
} from "./types";

export const loadEmployeeAllDetail = (payload = "") => {
  return {
    type: LOAD_EMPLOYEE_ALL_DETAILS,
    payload,
  };
};

export const setEmployeeAllDetail = (payload: employeeReducer) => {
  return {
    type: SET_EMPLOYEE_ALL_DETAILS,
    payload,
  };
};

export const loadEmployeeDetail = () => {
  return {
    type: LOAD_EMPLOYEE_DETAIL,
  };
};
export const addUpdateEmployeeProfileAvatar = (payload: { file: File }) => {
  return {
    type: HANDLE_EMPLOYEE_PROFILE_AVATAR,
    payload,
  };
};
export const setEmployeeProfileAvatar = (payload: string) => {
  return {
    type: SET_EMPLOYEE_PROFILE_AVATAR,
    payload,
  };
};

export const addUpdateEmployeeDetail = (payload: employeeDetailUpdateProps) => {
  return {
    type: HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL,
    payload,
  };
};

export const setEmployeeDetail = (payload: employeeDetailProps) => {
  return {
    type: SET_EMPLOYEE_DETAIL,
    payload,
  };
};

export const loadEmployeeResumes = () => {
  return {
    type: LOAD_EMPLOYEE_RESUMES,
  };
};
export const addEmployeeResume = (payload: uploadResumeProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_RESUME,
    payload,
  };
};
export const deleteEmployeeResume = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_RESUME,
    payload,
  };
};
export const setEmployeeResumes = (payload: resumeProps[]) => {
  return {
    type: SET_EMPLOYEE_RESUMES,
    payload,
  };
};

export const loadEmployeeQualifications = () => {
  return {
    type: LOAD_EMPLOYEE_QUALIFICATIONS,
  };
};
export const addEmployeeQualification = (payload: qualificationProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_QUALIFICATION,
    payload,
  };
};
export const updateEmployeeQualification = (payload: qualificationProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYEE_QUALIFICATION,
    payload,
  };
};
export const deleteEmployeeQualification = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_QUALIFICATION,
    payload,
  };
};
export const setEmployeeQualifications = (payload: qualificationProps[]) => {
  return {
    type: SET_EMPLOYEE_QUALIFICATIONS,
    payload,
  };
};

export const loadEmployeeSkills = () => {
  return {
    type: LOAD_EMPLOYEE_SKILLS,
  };
};
export const addUpdateEmployeeSkills = (payload: skillProps[]) => {
  return {
    type: HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS,
    payload,
  };
};
export const deleteEmployeeSkill = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_SKILL,
    payload,
  };
};

export const setEmployeeSkills = (payload: skillProps[]) => {
  return {
    type: SET_EMPLOYEE_SKILLS,
    payload,
  };
};

export const loadEmployeeLanguages = () => {
  return {
    type: LOAD_EMPLOYEE_LANGUAGES,
  };
};
export const addUpdateEmployeeLanguages = (payload: languageProps[]) => {
  return {
    type: HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES,
    payload,
  };
};
export const deleteEmployeeLanguage = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_LANGUAGE,
    payload,
  };
};
export const setEmployeeLanguages = (payload: languageProps[]) => {
  return {
    type: SET_EMPLOYEE_LANGUAGES,
    payload,
  };
};

export const loadEmployeeExperiences = () => {
  return {
    type: LOAD_EMPLOYEE_EXPERIENCES,
  };
};
export const addEmployeeExperience = (payload: experienceProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_EXPERIENCE,
    payload,
  };
};
export const updateEmployeeExperience = (payload: experienceProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYEE_EXPERIENCE,
    payload,
  };
};
export const deleteEmployeeExperience = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_EXPERIENCE,
    payload,
  };
};
export const setEmployeeExperiences = (payload: experienceProps[]) => {
  return {
    type: SET_EMPLOYEE_EXPERIENCES,
    payload,
  };
};

export const loadEmployeeCertificates = () => {
  return {
    type: LOAD_EMPLOYEE_CERTIFICATES,
  };
};
export const addEmployeeCertificate = (payload: certificateProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_CERTIFICATE,
    payload,
  };
};
export const updateEmployeeCertificate = (payload: certificateProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYEE_CERTIFICATE,
    payload,
  };
};
export const deleteEmployeeCertificate = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_CERTIFICATE,
    payload,
  };
};
export const setEmployeeCertificates = (payload: certificateProps[]) => {
  return {
    type: SET_EMPLOYEE_CERTIFICATES,
    payload,
  };
};

export const loadEmployeeProjects = () => {
  return {
    type: LOAD_EMPLOYEE_PROJECTS,
  };
};
export const addEmployeeProject = (payload: projectProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_PROJECT,
    payload,
  };
};
export const updateEmployeeProject = (payload: projectProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYEE_PROJECT,
    payload,
  };
};
export const deleteEmployeeProject = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_PROJECT,
    payload,
  };
};
export const setEmployeeProjects = (payload: projectProps[]) => {
  return {
    type: SET_EMPLOYEE_PROJECTS,
    payload,
  };
};

export const loadEmployeePreferredLocations = () => {
  return {
    type: LOAD_EMPLOYEE_PREFERRED_LOCATIONS,
  };
};
export const addUpdateEmployeePreferredLocations = (payload: skillProps[]) => {
  return {
    type: HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS,
    payload,
  };
};
export const deleteEmployeePreferredLocation = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION,
    payload,
  };
};
export const setEmployeePreferredLocations = (
  payload: preferredLocationProps[]
) => {
  return {
    type: SET_EMPLOYEE_PREFERRED_LOCATIONS,
    payload,
  };
};

export const loadEmployeeSocialMedia = () => {
  return {
    type: LOAD_EMPLOYEE_SOCIALMEDIA,
  };
};
export const addEmployeeSocialMedia = (payload: socialMediaProps) => {
  return {
    type: HANDLE_ADD_EMPLOYEE_SOCIALMEDIA,
    payload,
  };
};
export const updateEmployeeSocialMedia = (payload: socialMediaProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA,
    payload,
  };
};
export const deleteEmployeeSocialMedia = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA,
    payload,
  };
};
export const setEmployeeSocialMedia = (payload: socialMediaProps[]) => {
  return {
    type: SET_EMPLOYEE_SOCIALMEDIA,
    payload,
  };
};
