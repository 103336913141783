export const HANDLE_LOGIN = "HANDLE_LOGIN";
export const HANDLE_DELETE_ACCOUNT= "HANDLE_DELETE_ACCOUNT";
export const HANDLE_USER_VALIDATION = "HANDLE_USER_VALIDATION";
export const HANDLE_REGISTER = "HANDLE_REGISTER";
export const HANDLE_EMPLOYER_REGISTER = "HANDLE_EMPLOYER_REGISTER";
export const HANDLE_FORGOT_PASSWORD = "HANDLE_FORGOT_PASSWORD";
export const HANDLE_RESET_PASSWORD = "HANDLE_RESET_PASSWORD";
export const HANDLE_CHANGE_PASSWORD = "HANDLE_CHANGE_PASSWORD";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const REMOVE_AUTH_USER = "REMOVE_AUTH_USER";
