import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_COUNTRIES,
  LOAD_STATES_BY_COUNTRY,
  LOAD_CITIES_BY_STATE,
  LOAD_CITIES_BY_SEARCH,
} from "./types";
import {
  fetchCitiesBySearchService,
  fetchCitiesByStateIdService,
  fetchCountriesService,
  fetchStatesByCountryIdService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setCities, setCountries, setStates } from "./actions";

// function* hanldeLoadCountries(): Generator<any> {
//   try {
//     yield put(setLoader({ transparent: true }));
//     const responseData: any = yield call(fetchCountriesService);
//     yield put(setCountries(responseData));
//   } catch (error:any) {
//     yield put(setAlert({ text: error?.message, type: "error" }));
//   }
//   yield put(removeLoader());
// }

function* handleLoadStatesByCountry({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchStatesByCountryIdService,
      payload?.id
    );
    yield put(setStates(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadCitiesByState({ payload }: any): Generator<any> {
  if (!payload?.id) {
    return;
  }
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(
      fetchCitiesByStateIdService,
      payload?.id
    );
    yield put(setCities(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadCitiesBySearch({ payload }: any): Generator<any> {
  try {
    yield put(setLoader({ transparent: true, type: "dropdown" }));
    const responseData: any = yield call(fetchCitiesBySearchService, payload);
    yield put(setCities(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* locationSaga() {
  // yield takeLatest<any>(LOAD_COUNTRIES, hanldeLoadCountries);
  yield takeLatest<any>(LOAD_STATES_BY_COUNTRY, handleLoadStatesByCountry);
  yield takeLatest<any>(LOAD_CITIES_BY_STATE, handleLoadCitiesByState);
  yield takeLatest<any>(LOAD_CITIES_BY_SEARCH, handleLoadCitiesBySearch);
}
