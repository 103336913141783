import React from "react";
import "../../views/home/css/style.css";
import "../../views/home/css/slick-theme.css";
import "../../views/home/css/slick.css";
import "../../views/home/css/bootstrap.min.css";
import AOS from "aos";
import { Link } from "react-router-dom";
import AccountMenu from "../../layouts/EmployeeLayout/Unauthorized/Header/Account";
import logo from "../../views/home/images/Build-my-cv-logo.png";
const Components = () => {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: "ease-in-sine",
    delay: 100,
  });
  return (
    <header className="topsticky z-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-3">
            <div className="logo-site">
              <Link to="/home">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-9 ">
            <ul className="navigation-bar">
              <li>
                <Link to="/privacy-policy"> Privacy policy</Link>
              </li>

              <li>
                <a href="#"> {/* <AccountMenu />{" "} */}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Components;
