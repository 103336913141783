import http from "./http";

const controller: string = "Data/";

export const fetchAllOptionsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetAllOptions");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchSkillOptionsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetSkillOptions");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchLanguageOptionsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetLanguageOptions");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchQualificationOptionsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetQualificationOptions");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchQualificationCourseOptionsService = async (id: number) => {
  try {
    const responseData = await http.Get(
      controller + "GetCoursesByQualificationId?id=" + id
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCourseSpecializationOptionsService = async (id: number) => {
  try {
    const responseData = await http.Get(
      controller + "GetSpecializationsByCourseId?id=" + id
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchJobDepartmentOptionsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetJobDepartmentOptions");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchJobRoleCategoryOptionsService = async (
  id: number | number[]
) => {
  const payload = typeof id === "number" ? [] : id;
  const payloadId = typeof id === "number" ? id : 0;
  try {
    const responseData = await http.Patch(
      controller + "GetJobRoleCategoryOptionsByDepartmentId?id=" + payloadId,
      payload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchJobRoleOptionsService = async (id: number | number[]) => {
  const payload = typeof id === "number" ? [] : id;
  const payloadId = typeof id === "number" ? id : 0;
  try {
    const responseData = await http.Patch(
      controller + "GetJobRoleOptionsByJobCategoryId?id=" + payloadId,
      payload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchBoardUniversityOptionsService = async (search) => {
  try {
    const searchText = search ? search : "";
    const responseData = await http.Get(
      controller + "GetBoardUniversityOptions?search=" + searchText
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};
