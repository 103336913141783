import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_EMPLOYEE_LIST, SET_EMPLOYER_LIST } from "./types";
import { adminReducer } from "../../interfaces";

const initialeState: adminReducer = {
  employees: [],
  employers: [],
};
export const admin = (
  state = initialeState,
  action: PayloadAction<any[]> = null
): adminReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_EMPLOYEE_LIST: {
      return { ...state, employees: payload };
    }
    case SET_EMPLOYER_LIST: {
      return { ...state, employers: payload };
    }
    default: {
      return state;
    }
  }
};
