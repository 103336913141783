import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_JOBS,
  HANDLE_ADD_JOB,
  HANDLE_UPDATE_JOB,
  HANDLE_DELETE_JOB,
  LOAD_JOB,
  LOAD_SAVED_JOBS,
  LOAD_APPLIED_JOBS,
  HANDLE_SAVE_JOB,
  HANDLE_APPLY_JOB,
} from "./types";
import {
  deleteJobService,
  updateJobService,
  addJobService,
  fetchJobsService,
  fetchJobService,
  fetchSavedJobsService,
  fetchAppliedJobsService,
  applyJobService,
  saveJobService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setAppliedJobs, setJob, setJobs, setSavedJobs } from "./actions";
import { jobProps } from "src/interfaces";
import { store } from "src/store";

function* handleLoadJobs({ payload }: { payload?: any }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchJobsService, payload);
    yield put(setJobs(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadJob({
  payload,
}: {
  payload?: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchJobService, payload?.id);
    yield put(setJob(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadSavedJob({
  payload,
}: {
  payload?: { id: number };
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchSavedJobsService);
    yield put(setSavedJobs(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
}

function* handleSaveJob({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(saveJobService, payload);
    if (responseData) {
      var jobs: any[] = store?.getState()?.jobs?.saved || [];
      yield put(setSavedJobs([...jobs, responseData]));
    }
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadAppliedJob({
  payload,
}: {
  payload?: { id: number };
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchAppliedJobsService);
    yield put(setAppliedJobs(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
}

function* handleApplyJob({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(applyJobService, payload);
    if (responseData) {
      var jobs: any[] = store?.getState()?.jobs?.applied || [];
      yield put(setAppliedJobs([...jobs, responseData]));
    }
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddJob({ payload }: { payload: jobProps }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(addJobService, payload);
    var jobs: any[] = store?.getState()?.jobs?.all || [];
    yield put(setJobs([...jobs, payload]));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateJob({ payload }: { payload: jobProps }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(updateJobService, payload);
    var jobs: any[] = store?.getState()?.jobs?.all;
    yield put(
      setJobs([...jobs?.filter((item) => item.id != payload?.id), payload])
    );
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteJob({
  payload,
}: {
  payload: { id: number };
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(deleteJobService, payload);
    var jobs: any[] = store?.getState()?.jobs?.all;
    yield put(setJobs(jobs?.filter((item) => item.id != payload?.id)));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* jobSaga() {
  yield takeLatest<any>(LOAD_JOBS, handleLoadJobs);
  yield takeLatest<any>(LOAD_SAVED_JOBS, handleLoadSavedJob);
  yield takeLatest<any>(LOAD_APPLIED_JOBS, handleLoadAppliedJob);
  yield takeLatest<any>(LOAD_JOB, handleLoadJob);
  yield takeLatest<any>(HANDLE_ADD_JOB, handleAddJob);
  yield takeLatest<any>(HANDLE_SAVE_JOB, handleSaveJob);
  yield takeLatest<any>(HANDLE_APPLY_JOB, handleApplyJob);
  yield takeLatest<any>(HANDLE_UPDATE_JOB, handleUpdateJob);
  yield takeLatest<any>(HANDLE_DELETE_JOB, handleDeleteJob);
}
