import {
  authUserProps,
  changePasswordProps,
  deleteUserProps,
  employerRegisterProps,
  loginProps,
  registerProps,
  resetPasswordProps,
  userValidationProps,
} from "../../interfaces";
import {
  HANDLE_LOGIN,
  HANDLE_USER_VALIDATION,
  HANDLE_REGISTER,
  SET_AUTH_USER,
  REMOVE_AUTH_USER,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_RESET_PASSWORD,
  HANDLE_CHANGE_PASSWORD,
  HANDLE_EMPLOYER_REGISTER,
  HANDLE_DELETE_ACCOUNT,
} from "./types";

export const login = (payload: loginProps) => {
  return {
    type: HANDLE_LOGIN,
    payload,
  };
};
export const userValidation = (payload: userValidationProps) => {
  return {
    type: HANDLE_USER_VALIDATION,
    payload,
  };
};
export const register = (payload: registerProps) => {
  return {
    type: HANDLE_REGISTER,
    payload,
  };
};
export const employerRegister = (payload: employerRegisterProps) => {
  return {
    type: HANDLE_EMPLOYER_REGISTER,
    payload,
  };
};
export const forgotPassword = (payload: string) => {
  return {
    type: HANDLE_FORGOT_PASSWORD,
    payload,
  };
};
export const deleteAccount = (payload: deleteUserProps) => {
  return {
    type: HANDLE_DELETE_ACCOUNT,
    payload,
  };
};



export const resetPassword = (payload: resetPasswordProps) => {
  return {
    type: HANDLE_RESET_PASSWORD,
    payload,
  };
};
export const changePassword = (payload: changePasswordProps) => {
  return {
    type: HANDLE_CHANGE_PASSWORD,
    payload,
  };
};
export const setAuthUser = (payload: authUserProps) => {
  return {
    type: SET_AUTH_USER,
    payload,
  };
};
export const removeAuthUser = () => {
  return {
    type: REMOVE_AUTH_USER,
  };
};
