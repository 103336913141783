import {
  employerDetailProps,
  employerProfileProps,
} from "../../interfaces";
import {
  HANDLE_UPDATE_EMPLOYER_DETAIL,
  HANDLE_UPDATE_EMPLOYER_PROFILE,
  LOAD_EMPLOYER_DETAIL,
  SET_EMPLOYER_DETAIL,
} from "./types";

export const loadEmployerDetail = () => {
  return {
    type: LOAD_EMPLOYER_DETAIL,
  };
};
export const updateEmployerProfile = (payload: employerProfileProps) => {
  return {
    type: HANDLE_UPDATE_EMPLOYER_PROFILE,
    payload,
  };
};
export const updateEmployerDetail = (
  payload: employerDetailProps
) => {
  return {
    type: HANDLE_UPDATE_EMPLOYER_DETAIL,
    payload,
  };
};
export const setEmployerDetail = (payload: employerDetailProps) => {
  return {
    type: SET_EMPLOYER_DETAIL,
    payload,
  };
};
