import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_EMPLOYER_DETAIL,
  HANDLE_UPDATE_EMPLOYER_DETAIL,
  HANDLE_UPDATE_EMPLOYER_PROFILE,
} from "./types";
import {
  fetchEmployerPersonalDetailService,
  updateEmployerPersonalDetailService,
  updateEmployerProfileService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { loadEmployerDetail, setEmployerDetail } from "./actions";
import {
  employerDetailUpdateProps,
  employerProfileProps,
} from "src/interfaces";
import { setAuthUser } from "../auth";

function* handleLoadEmployerDetail(): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchEmployerPersonalDetailService);
    yield put(setEmployerDetail(responseData));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateEmployerProfile({
  payload,
}: {
  payload: employerProfileProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(updateEmployerProfileService, payload);
    yield put(setAuthUser({ fullName: payload.fullName } as any));
    yield put(loadEmployerDetail());
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateCompanyDetail({
  payload,
}: {
  payload: employerDetailUpdateProps;
}): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(updateEmployerPersonalDetailService, payload);
    yield put(loadEmployerDetail());
    yield put(setAlert({ text: responseData, type: "success" }));
  } catch (error:any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* employerSaga() {
  yield takeLatest<any>(LOAD_EMPLOYER_DETAIL, handleLoadEmployerDetail);
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYER_PROFILE,
    handleUpdateEmployerProfile
  );
  yield takeLatest<any>(
    HANDLE_UPDATE_EMPLOYER_DETAIL,
    handleUpdateCompanyDetail
  );
}
