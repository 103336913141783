import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";

type SidebarMenuObject = {
  title?: string;
  path?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: SidebarMenuObject[];
};

const menus = (role?: string): SidebarMenuObject[] => {
  let menuList: SidebarMenuObject[] = [];
  menuList.push({
    title: "Dashboard",
    path: "/dashboard",
    startIcon: <DesignServicesTwoToneIcon />,
  });
  if (role === "Admin") {
    menuList.push(
      {
        title: "Users",
        path: "/users",
        children: [
          {
            title: "Employees",
            path: "/employees",
            startIcon: <VerifiedUserIcon />,
          },
          {
            title: "Employers",
            path: "/employers",
            startIcon: <VerifiedUserIcon />,
          },
        ],
      },
      {
        title: "Manage",
        path: "/manage",
        children: [
          {
            title: "Job Posts",
            path: "/job-posts",
            startIcon: <VerifiedUserIcon />,
          },
        ],
      }
    );
  }
  if (role === "Employer") {
    // menuList.push({
    //   title: "Manage",
    //   path: "/manage",
    //   children: [
    //     {
    //       title: "Users",
    //       path: "/users",
    //       startIcon: <VerifiedUserIcon />,
    //     },
    //   ],
    // });
  }
  return menuList;
};

export default menus;
