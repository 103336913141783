import { useContext } from "react";

import { ListSubheader, List, Button, ListItem } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

import { connect } from "react-redux";
import { authUserProps } from "src/interfaces";
import { MenuWrapper, SubMenuWrapper } from "./components";
import menus from "./constants";

function SidebarMenu({ auth }: { auth: authUserProps }) {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        {menus(auth?.role).map((item, index) => {
          if (item?.children?.length >= 0) {
            return (
              <List
                key={index}
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    {item.title}
                  </ListSubheader>
                }
              >
                {item?.children?.map((subItem, subIndex) => (
                  <SubMenuWrapper key={subIndex}>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={item?.path + subItem?.path}
                          startIcon={subItem?.startIcon}
                          endIcon={subItem?.endIcon}
                        >
                          {subItem?.title}
                        </Button>
                      </ListItem>
                    </List>
                  </SubMenuWrapper>
                ))}
              </List>
            );
          } else {
            return (
              <SubMenuWrapper key={index}>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={item?.path}
                      startIcon={item?.startIcon}
                      endIcon={item?.endIcon}
                    >
                      {item?.title}
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            );
          }
        })}
      </MenuWrapper>
    </>
  );
}

const mapStateToProps = ({ authUser }) => ({ auth: authUser });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
