import { useContext } from "react";
import Scrollbar from "src/components/Scrollbar";
import { SidebarContext } from "src/contexts/SidebarContext";

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
} from "@mui/material";

import SidebarMenu from "./SidebarMenu";
import Logo from "src/components/LogoWhite";
import { connect } from "react-redux";
import { removeAuthUser } from "src/store";
import { authUserProps } from "src/interfaces";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.employer.sidebar.width};
        min-width: ${theme.employer.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

const Sidebar = (props: { signOut: Function; auth: authUserProps }) => {
  const { signOut, auth } = props;
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
          background:
            theme.palette.mode === "dark"
              ? alpha(
                  lighten(
                    auth?.role === "Admin"
                      ? theme.admin.header.background
                      : theme.employer.header.background,
                    0.1
                  ),
                  0.5
                )
              : auth?.role === "Admin"
              ? theme.admin.sidebar.background
              : theme.employer.sidebar.background,
          boxShadow:
            theme.palette.mode === "dark"
              ? auth?.role === "Admin"
                ? theme.admin.sidebar.boxShadow
                : theme.employer.sidebar.boxShadow
              : "none",
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 150,
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <Box p={2}>
          <Button
            href="#"
            onClick={() => signOut()}
            rel="noopener noreferrer"
            variant="contained"
            color="warning"
            size="small"
            fullWidth
          >
            Logout
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${
            auth?.role === "Admin"
              ? theme.admin.sidebar.boxShadow
              : theme.employer.sidebar.boxShadow
          }`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === "dark"
                ? alpha(
                    lighten(
                      auth?.role === "Admin"
                        ? theme.admin.header.background
                        : theme.employer.header.background,
                      0.1
                    ),
                    0.5
                  )
                : auth?.role === "Admin"
                ? theme.admin.sidebar.background
                : theme.employer.sidebar.background,
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 150,
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ authUser }) => ({ auth: authUser });
const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(removeAuthUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
