import { employerDetailUpdateProps } from "src/interfaces";
import http from "./http";

const controller: string = "Employer/";

export const fetchEmployerPersonalDetailService = async () => {
  try {
    const responseData = await http.Get(controller + "GetEmployerDetail");
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const updateEmployerProfileService = async (payload: any) => {
  try {
    const responseData = await http.Post(controller + "UpdateEmployerProfile", payload);
    return responseData
  } catch (error:any) {
    throw error;
  }
};

export const updateEmployerPersonalDetailService = async (
  payload: employerDetailUpdateProps
) => {
  try {
    const responseData = await http.Post(
      controller + "UpdateEmployerDetail",
      payload
    );
    return responseData
  } catch (error:any) {
    throw error;
  }
};
