import AppContent from "./AppContent";
import AppProviders from "./AppProviders";

function App() {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
}
export default App;
