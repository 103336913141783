import { connect } from "react-redux";
import AuthorizedLayout from "./Authorized";
import UnauthorizedLayout from "./Unauthorized";
import { authUserProps } from "src/interfaces";

const EmployeeLayout = ({ auth }: { auth: authUserProps }) => {
  if (auth?.isLoggedIn) {
    return <AuthorizedLayout />;
  }
  return <UnauthorizedLayout />;
};

const mapStateToProps = ({ authUser }) => ({ auth: authUser });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLayout);
