import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_TEMPLATES } from "./types";
import { fetchTemplatesService } from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setTemplates } from "./actions";

function* handleLoadTemplates({ payload }: { payload?: any }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(fetchTemplatesService);
    yield put(setTemplates(responseData));
  } catch (error: any) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* templateSaga() {
  yield takeLatest<any>(LOAD_TEMPLATES, handleLoadTemplates);
}
