export const LOAD_EMPLOYEE_ALL_DETAILS = "LOAD_EMPLOYEE_ALL_DETAILS";
export const LOAD_EMPLOYEE_DETAIL = "LOAD_EMPLOYEE_DETAIL";
export const HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL = "HANDLE_ADD_UPDATE_EMPLOYEE_DETAIL";
export const HANDLE_EMPLOYEE_PROFILE_AVATAR = "HANDLE_EMPLOYEE_PROFILE_AVATAR";
export const SET_EMPLOYEE_PROFILE_AVATAR = "SET_EMPLOYEE_PROFILE_AVATAR";
export const LOAD_EMPLOYEE_RESUMES = "LOAD_EMPLOYEE_RESUMES";
export const HANDLE_ADD_EMPLOYEE_RESUME = "HANDLE_ADD_EMPLOYEE_RESUME";
export const HANDLE_DELETE_EMPLOYEE_RESUME = "HANDLE_DELETE_EMPLOYEE_RESUME";
export const SET_EMPLOYEE_RESUMES = "SET_EMPLOYEE_RESUMES";

export const LOAD_EMPLOYEE_SKILLS = "LOAD_EMPLOYEE_SKILLS";
export const HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS = "HANDLE_ADD_UPDATE_EMPLOYEE_SKILLS";
export const HANDLE_DELETE_EMPLOYEE_SKILL = "HANDLE_DELETE_EMPLOYEE_SKILL";
export const SET_EMPLOYEE_SKILLS = "SET_EMPLOYEE_SKILLS";

export const SET_EMPLOYEE_ALL_DETAILS = "SET_EMPLOYEE_ALL_DETAILS";
export const SET_EMPLOYEE_DETAIL = "SET_EMPLOYEE_DETAIL";

export const LOAD_EMPLOYEE_QUALIFICATIONS = "LOAD_EMPLOYEE_QUALIFICATIONS";
export const HANDLE_ADD_EMPLOYEE_QUALIFICATION = "HANDLE_ADD_EMPLOYEE_QUALIFICATION";
export const HANDLE_UPDATE_EMPLOYEE_QUALIFICATION = "HANDLE_UPDATE_EMPLOYEE_QUALIFICATION";
export const HANDLE_DELETE_EMPLOYEE_QUALIFICATION = "HANDLE_DELETE_EMPLOYEE_QUALIFICATION";
export const SET_EMPLOYEE_QUALIFICATIONS = "SET_EMPLOYEE_QUALIFICATIONS";

export const LOAD_EMPLOYEE_EXPERIENCES = "LOAD_EMPLOYEE_EXPERIENCES";
export const HANDLE_ADD_EMPLOYEE_EXPERIENCE = "HANDLE_ADD_EMPLOYEE_EXPERIENCE";
export const HANDLE_UPDATE_EMPLOYEE_EXPERIENCE = "HANDLE_UPDATE_EMPLOYEE_EXPERIENCE";
export const HANDLE_DELETE_EMPLOYEE_EXPERIENCE = "HANDLE_DELETE_EMPLOYEE_EXPERIENCE";
export const SET_EMPLOYEE_EXPERIENCES = "SET_EMPLOYEE_EXPERIENCES";

export const LOAD_EMPLOYEE_CERTIFICATES = "LOAD_EMPLOYEE_CERTIFICATES";
export const HANDLE_ADD_EMPLOYEE_CERTIFICATE = "HANDLE_ADD_EMPLOYEE_CERTIFICATE";
export const HANDLE_UPDATE_EMPLOYEE_CERTIFICATE = "HANDLE_UPDATE_EMPLOYEE_CERTIFICATE";
export const HANDLE_DELETE_EMPLOYEE_CERTIFICATE = "HANDLE_DELETE_EMPLOYEE_CERTIFICATE";
export const SET_EMPLOYEE_CERTIFICATES = "SET_EMPLOYEE_CERTIFICATES";

export const LOAD_EMPLOYEE_PROJECTS = "LOAD_EMPLOYEE_PROJECTS";
export const HANDLE_ADD_EMPLOYEE_PROJECT = "HANDLE_ADD_EMPLOYEE_PROJECT";
export const HANDLE_UPDATE_EMPLOYEE_PROJECT = "HANDLE_UPDATE_EMPLOYEE_PROJECT";
export const HANDLE_DELETE_EMPLOYEE_PROJECT = "HANDLE_DELETE_EMPLOYEE_PROJECT";
export const SET_EMPLOYEE_PROJECTS = "SET_EMPLOYEE_PROJECTS";

export const LOAD_EMPLOYEE_PREFERRED_LOCATIONS = "LOAD_EMPLOYEE_PREFERRED_LOCATIONS";
export const HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS = "HANDLE_ADD_UPDATE_EMPLOYEE_PREFERRED_LOCATIONS";
export const HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION = "HANDLE_DELETE_EMPLOYEE_PREFERRED_LOCATION";
export const SET_EMPLOYEE_PREFERRED_LOCATIONS =
  "SET_EMPLOYEE_PREFERRED_LOCATIONS";

  export const LOAD_EMPLOYEE_LANGUAGES = "LOAD_EMPLOYEE_LANGUAGES";
  export const HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES = "HANDLE_ADD_UPDATE_EMPLOYEE_LANGUAGES";
  export const HANDLE_DELETE_EMPLOYEE_LANGUAGE = "HANDLE_DELETE_EMPLOYEE_LANGUAGE";
  export const SET_EMPLOYEE_LANGUAGES = "SET_EMPLOYEE_LANGUAGES";
  
  export const LOAD_EMPLOYEE_SOCIALMEDIA = "LOAD_EMPLOYEE_SOCIALMEDIA";
  export const HANDLE_ADD_EMPLOYEE_SOCIALMEDIA = "HANDLE_ADD_EMPLOYEE_SOCIALMEDIA";
  export const HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA = "HANDLE_UPDATE_EMPLOYEE_SOCIALMEDIA";
  export const HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA = "HANDLE_DELETE_EMPLOYEE_SOCIALMEDIA";
  export const SET_EMPLOYEE_SOCIALMEDIA = "SET_EMPLOYEE_SOCIALMEDIA";
