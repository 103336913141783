import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { connect } from "react-redux";
import { loaderProps } from "src/interfaces";

function Spinner({
  loader: { visible, transparent, type },
}: {
  loader: loaderProps;
}) {
  if (!visible || type != "main") {
    return null;
  }
  const bg = transparent
    ? {
        backgroundColor: "#00000099",
      }
    : {};
  return (
    <Box
      sx={{
        ...bg,
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          width: 250,
        }}
      >
        <LinearProgress />
        <LinearProgress variant="query" />
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ loader }) => ({
  loader,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
