import { jobDetailProps, jobProps } from "../../interfaces";
import {
  SET_JOBS,
  HANDLE_DELETE_JOB,
  HANDLE_UPDATE_JOB,
  HANDLE_ADD_JOB,
  LOAD_JOBS,
  SET_JOB,
  LOAD_JOB,
  SET_SAVED_JOBS,
  SET_APPLIED_JOBS,
  LOAD_SAVED_JOBS,
  LOAD_APPLIED_JOBS,
  HANDLE_SAVE_JOB,
  HANDLE_APPLY_JOB,
} from "./types";

export const loadJobs = (payload?: any) => {
  return {
    type: LOAD_JOBS,
    payload,
  };
};

export const loadSavedJobs = () => {
  return {
    type: LOAD_SAVED_JOBS,
  };
};

export const loadAppliedJobs = () => {
  return {
    type: LOAD_APPLIED_JOBS,
  };
};

export const loadJob = (payload?: any) => {
  return {
    type: LOAD_JOB,
    payload,
  };
};
export const addJob = (payload: jobProps) => {
  return {
    type: HANDLE_ADD_JOB,
    payload,
  };
};
export const updateJob = (payload: jobProps) => {
  return {
    type: HANDLE_UPDATE_JOB,
    payload,
  };
};
export const deleteJob = (payload: { id: number }) => {
  return {
    type: HANDLE_DELETE_JOB,
    payload,
  };
};
export const saveJob = (payload: { id: number }) => {
  return {
    type: HANDLE_SAVE_JOB,
    payload,
  };
};
export const applyJob = (payload: { id: number }) => {
  return {
    type: HANDLE_APPLY_JOB,
    payload,
  };
};
export const setJobs = (payload: jobDetailProps[]) => {
  return {
    type: SET_JOBS,
    payload,
  };
};
export const setSavedJobs = (payload: jobDetailProps[]) => {
  return {
    type: SET_SAVED_JOBS,
    payload,
  };
};
export const setAppliedJobs = (payload: jobDetailProps[]) => {
  return {
    type: SET_APPLIED_JOBS,
    payload,
  };
};
export const setJob = (payload: jobDetailProps) => {
  return {
    type: SET_JOB,
    payload,
  };
};
