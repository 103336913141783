import {
  cityProps,
  countryProps,
  employerDetailProps,
  stateProps,
} from "../../interfaces";
import {
  LOAD_CITIES_BY_STATE,
  LOAD_COUNTRIES,
  LOAD_STATES_BY_COUNTRY,
  LOAD_CITIES_BY_SEARCH,
  SET_CITIES,
  SET_COUNTRIES,
  SET_STATES,
} from "./types";

export const loadCountries = () => {
  return {
    type: LOAD_COUNTRIES,
  };
};

export const setCountries = (payload: countryProps[]) => {
  return {
    type: SET_COUNTRIES,
    payload,
  };
};

export const loadStatesByCountry = (payload: { id: number }) => {
  return {
    type: LOAD_STATES_BY_COUNTRY,
    payload,
  };
};

export const setStates = (payload: stateProps[]) => {
  return {
    type: SET_STATES,
    payload,
  };
};

export const loadCitiesByState = (payload: { id: number }) => {
  return {
    type: LOAD_CITIES_BY_STATE,
    payload,
  };
};

export const loadCitiesBySearch = (payload: any) => {
  return {
    type: LOAD_CITIES_BY_SEARCH,
    payload,
  };
};

export const setCities = (payload: cityProps[]) => {
  return {
    type: SET_CITIES,
    payload,
  };
};
