import {
  employeeDetailProps,
  employeeDetailUpdateProps,
  experienceProps,
  certificateProps,
  preferredLocationProps,
  projectProps,
  qualificationProps,
  skillProps,
  uploadResumeProps,
  languageProps,
  socialMediaProps,
} from "src/interfaces";
import http from "./http";
import { AnyARecord } from "dns";

const controller: string = "Employee/";

export const fetchEmployeePersonalDetailService = async () => {
  try {
    const responseData = await http.Get(controller + "GetEmployeeDetail");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeAllDetailService = async (token = "") => {
  try {
    const responseData = await http.Get(
      controller + "GetEmployeeAllDetail",
      token
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addUpdateEmployeeDetailService = async (
  payload: employeeDetailUpdateProps
) => {
  try {
    const responseData = await http.Post(
      controller + "AddUpdateEmployeeDetail",
      {
        ...payload,
        dob: new Date(
          new Date(payload.dob).setMonth(new Date(payload.dob).getMonth())
        ),
      }
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeResumesService = async () => {
  try {
    const responseData = await http.Get(controller + "GetResumes");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeResumeService = async (payload: uploadResumeProps) => {
  try {
    const responseData = await http.Post(
      controller + "UploadResume",
      payload,
      "multipart/form-data"
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeResumeService = async (payload: { id: number }) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteResume",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeQualificationsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetQualifications");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeQualificationService = async (
  payload: qualificationProps
) => {
  try {
    const responseData = await http.Post(
      controller + "AddQualification",
      payload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeQualificationService = async (
  payload: qualificationProps
) => {
  try {
    const responseData = await http.Post(
      controller + "UpdateQualification",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeQualificationService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteQualification",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeSkillsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetSkills");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addUpdateEmployeeSkillsService = async (payload: skillProps[]) => {
  try {
    const responseData = await http.Post(
      controller + "AddUpdateSkills",
      payload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeSkillService = async (payload: { id: number }) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteSkill",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeLanguagesService = async () => {
  try {
    const responseData = await http.Get(controller + "GetLanguages");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addUpdateEmployeeLanguagesService = async (
  payload: languageProps[]
) => {
  try {
    const responseData = await http.Post(
      controller + "AddUpdateLanguages",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeLanguageService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteLanguage",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeExperiencesService = async () => {
  try {
    const responseData = await http.Get(controller + "GetExperiences");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeExperienceService = async (
  payload: experienceProps
) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "AddExperience",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeExperienceService = async (
  payload: experienceProps
) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "UpdateExperience",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeExperienceService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteExperience",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeCertificatesService = async () => {
  try {
    const responseData = await http.Get(controller + "GetCertificates");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeCertificateService = async (
  payload: certificateProps
) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "AddCertificate",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeCertificateService = async (
  payload: certificateProps
) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "UpdateCertificate",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeCertificateService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteCertificate",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeProjectsService = async () => {
  try {
    const responseData = await http.Get(controller + "GetProjects");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeProjectService = async (payload: projectProps) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "AddProject",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeProjectService = async (payload: projectProps) => {
  try {
    const finalPayload = {
      ...payload,
      startDate: new Date(
        new Date(payload.startDate).setMonth(
          new Date(payload.startDate).getMonth() + 1
        )
      ),
      endDate: new Date(
        new Date(payload.endDate).setMonth(
          new Date(payload.endDate).getMonth() + 1
        )
      ),
    };
    const responseData = await http.Post(
      controller + "UpdateProject",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeProjectService = async (payload: { id: number }) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteProject",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeePreferredLocationsService = async () => {
  try {
    const responseData = await http.Get(
      controller + "GetPreferredWorkLocations"
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addUpdateEmployeePreferredLocationsService = async (
  payload: preferredLocationProps[]
) => {
  try {
    const responseData = await http.Post(
      controller + "AddUpdatePreferredWorkLocations",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeePreferredLocationService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeletePreferredWorkLocation",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEmployeeSocialMediaService = async () => {
  try {
    const responseData = await http.Get(controller + "GetSocialMedia");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployeeSocialMediaService = async (
  payload: socialMediaProps
) => {
  try {
    const finalPayload = {
      ...payload,
    };
    const responseData = await http.Post(
      controller + "AddSocialMedia",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeSocialMediaService = async (
  payload: socialMediaProps
) => {
  try {
    const finalPayload = {
      ...payload,
    };
    const responseData = await http.Post(
      controller + "UpdateSocialMedia",
      finalPayload
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployeeSocialMediaService = async (payload: {
  id: number;
}) => {
  try {
    const responseData = await http.Delete(
      controller + "DeleteSocialMedia",
      payload as any
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};
