import http from "./http";

const controller: string = "Data/";

export const fetchCountriesService = async () => {
  try {
    const responseData = await http.Get(controller + "GetCountries");
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchStatesByCountryIdService = async (id: number) => {
  try {
    const responseData = await http.Get(
      controller + "GetStatesByCountryId?id=" + id
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCitiesByStateIdService = async (id: number) => {
  try {
    const responseData = await http.Get(
      controller + "GetCitiesByStateId?id=" + id
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCitiesBySearchService = async (search: string) => {
  try {
    const responseData = await http.Get(
      controller + "GetCitiesBySearch?search=" + search
    );
    return responseData;
  } catch (error: any) {
    throw error;
  }
};
